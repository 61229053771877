import React, { ReactNode } from "react";
import copy from "copy-to-clipboard";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    ClipboardPlus as CopyIcon
} from "react-bootstrap-icons";
import JSONPretty from "react-json-pretty";
import styled from "styled-components";

const ModalBody = styled(Modal.Body)`
    overflow-y: auto;
    max-height: calc(500px);
`;

type ViewMetadataModalProperties = {
    show: boolean,
    customer: string,
    metadata: Record<string, any>,
    closeCallback: () => void
}

export default class ViewMetadataModal extends React.Component<ViewMetadataModalProperties> {

    private copyToClipboard = () => {
        copy(JSON.stringify(this.props.metadata));
    }

    public render(): ReactNode {
        return (
            <Modal show={this.props.show} size="lg" onHide={this.props.closeCallback}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.customer} Metadata - Raw JSON</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <JSONPretty data={this.props.metadata} />
                </ModalBody>
                <Modal.Footer>
                    <OverlayTrigger overlay={<Tooltip id="copy-tooltip">Copy data to clipboard</Tooltip>}>
                        <Button variant="secondary" onClick={this.copyToClipboard}>
                            <CopyIcon size={20}/>
                        </Button>
                    </OverlayTrigger>
                </Modal.Footer>
            </Modal>
        );
    }
}