import { FilterUITypes } from "../enums";
import { getPortalCustomerPresets } from "../utils/Requests";

let presets: Array<Preset> = [];

export async function initialisePresets(groups: string, customer: string): Promise<void> {
    presets = await getPortalCustomerPresets(groups, customer);
}

export function getAvailablePresets(groups: string[], local: boolean): Array<Preset> {
    // Remove search and browse slots as these are catered for by other screen
    const availablePresets = presets.filter((preset: Preset) => {
        return (preset.slotType && (preset.slotType !== "search" && preset.slotType !== "browse"));
    });

    if (local) {
        availablePresets.unshift({
            display: "Local",
            slotId: "",
            userId: "",
            parameters: "",
            lookupItems: true,
            local: true
        });
    }

    return availablePresets;
}

export function getSearchPreset(customer: string): Preset | undefined {
    const searchPresets = presets.filter((preset: Preset) => {
        return preset.slotType === "search";
    });

    if (searchPresets.length > 0) {
        const searchPreset = searchPresets[0];

        if (customer === "epix") {
            searchPreset.popularTerms = [
                "Sonic the Hedgehog",
                "Pennyworth",
                "From",
                "Terminator: Dark Fate",
                "Godfather of Harlem"
            ];

            searchPreset.filters = [
                {
                    id: "category-dropdown",
                    name: "Genres",
                    type: FilterUITypes.Dropdown,
                    qsParam: "category",
                    defaultValue: "All Genres",
                    values: [
                        "All Genres",
                        "Action and Adventure",
                        "Comedy",
                        "Drama",
                        "Documentary",
                        "Erotic",
                        "Horror",
                        "Indie",
                        "Kids and Family",
                        "Romance",
                        "Science Fiction and Fantasy",
                        "Thriller",
                        "Stand-Up",
                        "Vault"
                    ]
                },
                {
                    id: "release-slider",
                    name: "Year",
                    type: FilterUITypes.RangeSlider,
                    qsMinParam: "releaseYearMin",
                    qsMaxParam: "releaseYearMax",
                    min: 1910,
                    max: Math.ceil(new Date().getFullYear()/5)*5,
                    steps: 5,
                    marks: {
                        1910: {
                            style: {
                                color: "white"
                            },
                            label: "1910"
                        },
                        2025: {
                            style: {
                                color: "white"
                            },
                            label: "2025"
                        }
                    },
                    showToolTip: true
                },
                {
                    id: "ratings-slider",
                    name: "Rating",
                    type: FilterUITypes.RangeSlider,
                    qsMinParam: "ratingMin",
                    qsMaxParam: "ratingMax",
                    min: 10,
                    max: 60,
                    steps: 10,
                    marks: {
                        10: {
                            style: { color: "white" },
                            label: "G"
                        },
                        20: {
                            style: { color: "white" },
                            label: "PG"
                        },
                        30: {
                            style: { color: "white" },
                            label: "PG-13"
                        },
                        40: {
                            style: { color: "white" },
                            label: "R"
                        },
                        50: {
                            style: { color: "white" },
                            label: "NC-17"
                        },
                        60: {
                            style: { color: "white" },
                            label: "NR"
                        }
                    },
                    useMarksAsValue: true
                }
            ];
        }

        if (customer === "rtve") {
            searchPreset.filters = [
                {
                    id: "typename-dropdown",
                    name: "Type",
                    type: FilterUITypes.Dropdown,
                    qsParam: "typename",
                    defaultValue: "All Types",
                    values: [
                        "All Types",
                        "Movie",
                        "TVSeries",
                        "RadioSeries",
                        "Article"
                    ]
                }
            ];
        }

        return searchPreset;
    }
}