import { BUILD_ENVIRONMENT } from "../constants";
import * as constants from "../constants";
import { getPortalCustomerConfig } from "../utils/Requests";


export let customerConfig: Array<CustomerConfig> = [];

export async function initialiseCustomerConfig(authGroups: string): Promise<void> {
    customerConfig = await getPortalCustomerConfig(authGroups);
}

export function getCustomerConfig(name: string | null): CustomerConfig {
    return customerConfig.find((customer) => {
        return customer.name === name;
    }) || customerConfig[0];
}

// ToDo: Can this be removed now?
export function getAvailableCustomers(groups: string[]): CustomerConfig[] {
    const lowerGroups = groups.map(v => v.toLowerCase());

    const customerConfigs = customerConfig.filter((customer) => {
        if (customer.authGroup && lowerGroups.includes(constants.DEMO_FULL_AUTH)) {
            if (customer.authGroup === constants.BACKSTAGE_DEMO_FULL_AUTH) {
                return true;
            }
        }

        if (customer.authGroup && !lowerGroups.includes(constants.THE_FILTER_FULL_AUTH)) {
            return lowerGroups?.includes(customer.authGroup);
        }

        return customer.environments.includes(BUILD_ENVIRONMENT);
    });

    return customerConfigs;
}
