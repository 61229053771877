export enum FilterUITypes {
    Dropdown,
    RangeSlider,
    Slider
}

export enum EntityTypes {
    all = "All",
    autofranchise = "AutoFranchise",
    franchise = "Franchise",
    genre = "Genre",
    people = "People",
    tag = "Tag",
    mood = "Mood",
    watchAssistantCategoryAutomated = "Category (Automated)",
    watchAssistantCategoryManual = "Category (Manual)",
    group = "Group"
}

export enum ComposerElementTypes {
    Carousel = "Carousel",
    FixedCarousel = "FixedCarousel",
    LargeCarousel = "LargeCarousel",
    FixedLargeCarousel = "FixedLargeCarousel",
    FixedUserCarousel = "FixedUserCarousel",
    Hero = "Hero",
    FixedHero = "FixedHero",
    Spacer = "Spacer",
    FullPagePersonalisation = "FullPagePersonalisation",
    FPPCarousel = "FPPCarousel",
    SlotCarousel = "SlotCarousel",
    SlotHero = "SlotHero",
    SlotLargeCarousel = "SlotLargeCarousel"
}

export enum UserClusterTypes {
    movie = "Movie",
    tvseries = "TV Series",
    family = "Family",
    mixed = "Mixed",
    action = "Action",
    drama = "Drama",
    documentary = "Documentary",
    reality = "Reality"
}

export enum SlotNames {
    none = "None",
    MostPopular = "Most Popular",
    Trending = "Trending",
    Latest = "Latest",
    LatestInGenre = "Latest in Genre"
}