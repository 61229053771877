import React, { ReactNode } from "react";
import { Alert, Button, Col, Form, InputGroup, OverlayTrigger, Spinner, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";

import { SlotNames } from "../../enums";
import { generateGuid } from "../../utils/Utils";

const ElementRow = styled(Row)`
    background-color: #282828;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 5px;
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    padding-left: 10px;
    padding-right: 10px;
`;

const HeaderRow = styled(Row)`
    border: thin solid #282828;
    padding: 10px 5px;
    margin-top: 10px;
    margin-left: -15px;
    margin-bottom: 20px;
    margin-right: -15px;
    border-radius: 3px;
`;

const ComposerForm = styled(Form)`
    width: 100%;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

const AssocAlert = styled(Alert)`
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

type SetupSlotGridState = {
    slotRows: SlotElement[],
    slotId: string,
    slotName: string,
    slotCustomer: string,
    slottime: string,
    slotType: string,
    slotGenre: string,
    slotCount: number,
    refresh: boolean,
    showSuccess: boolean,
    showError: boolean
}

type SetupSlotGridProperties = {
    groups: string[],
    slots: SlotElement[],
    count: number,
    fppSlots: SlotElement[],
    customer: string,
    saveCallback: (customer: string, slots: SlotElement[]) => Promise<void>
}

export default class SetupSlotGrid extends React.Component<SetupSlotGridProperties, SetupSlotGridState> {

    constructor(props: SetupSlotGridProperties) {
        super(props);

        this.state = {
            slotRows: this.props.slots,
            slotId: "",
            slotName: "",
            slotCustomer: this.props.customer,
            slottime: "",
            slotType: "",
            slotGenre: "",
            slotCount: this.props.count,
            refresh: false,
            showSuccess: false,
            showError: false
        };
    }

    public componentDidMount(): void {
        if (this.state.slotRows.length === 0) this.addNewSlotsRow();
    }

    private saveSlots = (): void => {
        // logic should go here to enable saving new slots
        // eslint-disable-next-line no-console
        console.log(this.state.slotCustomer, this.state.slotRows);
    }

    private addNewSlotsRow = (): void => {
        const slots = this.state.slotRows;
        slots.push({
            key: `slot-${this.state.slotCount}`,
            slotId: generateGuid(),
            slotName: SlotNames.MostPopular,
            customerName: "Top Shows",
            time: "7 Days"
        });

        this.setState({
            slotRows: slots,
            slotCount: this.state.slotCount + 1
        });
    }

    private setSlotField(
        element: SlotElement,
        field: "key" | "slotId" | "slotName" | "customerName" | "time" | "type" | "genre",
        event: React.ChangeEvent<HTMLInputElement>) {
        const slots = this.state.slotRows;
        const eleIndex = slots.findIndex(obj => obj.key === element.key);
        slots[eleIndex][field] = event.currentTarget.value;

        this.setState({
            slotRows: slots
        });
    }

    private setElementSlotId(
        element: SlotElement,
        event: React.ChangeEvent<HTMLInputElement>): void {
        const slotId = event.currentTarget.value;

        this.setSlotField(element, "slotId", event);

        this.setState({
            slotId: slotId
        });
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    private createSlotRow(slot: SlotElement, enabled: boolean) {
        return (
            <ElementRow key={slot.key}>
                <ComposerForm inline onSubmit={(event: Event) => { event.preventDefault(); }}>
                    <Col md={12}>
                        <Form.Row>
                            <Col md={3}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Slot ID</FieldName>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                        value={slot.slotId}
                                        disabled={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setElementSlotId(slot, event); }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={3}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Slot Name</FieldName>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        value={slot.slotName}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setSlotField(slot, "slotName", value); }}
                                        style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem", marginRight: "10px", width: "70%" }}>
                                        <option value={SlotNames.MostPopular} key="slot-name-most-popular">Most Popular</option>
                                        <option value={SlotNames.Trending} key="slot-name-trending">Trending</option>
                                        <option value={SlotNames.Latest} key="slot-name-latest">Latest</option>
                                        <option value={SlotNames.LatestInGenre} key="slot-name-latest-in-genre">Latest In Genre</option>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={3}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <FieldName>Customer Name</FieldName>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                        placeholder="Top Shows"
                                        value={slot.customerName}
                                        disabled={!enabled}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setSlotField(slot, "customerName", event); }}>
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            {(slot.slotName === SlotNames.MostPopular || slot.slotName === SlotNames.Trending) &&
                                <Col md={3}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName >Time</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            value={slot.time}
                                            disabled={!enabled}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setSlotField(slot, "time", value); }}
                                            style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem" }}>
                                            <option value="24 Hours" key="time-24-hours">24 Hours</option>
                                            <option value="7 Days" key="time-7-days">7 Days</option>
                                            <option value="30 Days" key="time-30-days">30 Days</option>
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            }
                            {slot.slotName === SlotNames.Latest &&
                                <Col md={3}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Type</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            value={slot.type}
                                            disabled={!enabled}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setSlotField(slot, "type", value); }}
                                            style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem" }}>
                                            <option value="Movies" key="type-movies">Movies</option>
                                            <option value="TV series" key="type-tv-series">TV Series</option>
                                            <option value="All" key="type-all">All</option>
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            }
                            {slot.slotName === SlotNames.LatestInGenre &&
                                <Col md={3}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Genre</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            value={slot.genre}
                                            disabled={!enabled}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setSlotField(slot, "genre", value); }}
                                            style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem" }}
                                        >
                                            <option value="Action and Adventure" key="genre-action">Action</option>
                                            <option value="Comedy" key="genre-comedy">Comedy</option>
                                            <option value="Drama" key="genre-drama">Drama</option>
                                            <option value="Documentary" key="genre-documentary">Documentary</option>
                                            <option value="Horror" key="genre-horror">Horror</option>
                                            <option value="Indie" key="genre-indie">Indie</option>
                                            <option value="Kids and Family" key="genre-family">Kids and Family</option>
                                            <option value="Romance" key="genre-romance">Romance</option>
                                            <option value="Science Fiction and Fantasy" key="genre-science-fiction">Science Fiction and Fantasy</option>
                                            <option value="Thriller" key="genre-thriller">Thriller</option>
                                            <option value="Stand-Up" key="genre-stand-up">Stand-Up</option>
                                            <option value="Vault" key="genre-vault">Vault</option>
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            }
                        </Form.Row>
                    </Col>
                </ComposerForm>
            </ElementRow >
        );
    }

    private deleteElement(key: string): void {
        let slots = this.state.slotRows;
        slots = slots.filter(obj => obj.key !== key);

        this.setState({
            slotRows: slots
        });
    }

    public render(): ReactNode {
        return (
            <>
                {this.state.refresh && <SpinnerDiv>
                    <Spinner animation="border" style={{ color: "white" }} />
                </SpinnerDiv>}

                <AssocAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Slots Submitted
                </AssocAlert>
                <AssocAlert
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error Submitting Slots
                </AssocAlert>
                <Row style={{ opacity: this.state.refresh ? 0.5 : 1 }}>
                    <Col md={{ span: 10, offset: 1 }}>
                        <HeaderRow>
                            <Col md={10}>
                                {/*Something will probably go here */}
                            </Col>
                            <Col md={2} className="d-flex justify-content-end">
                                {/* <OverlayTrigger
                                    placement="auto"
                                    overlay={<Tooltip id={"add-row-tooltip"}>Add New Row</Tooltip>}>
                                    <Button variant="secondary" style={{ marginRight: "10px" }} onClick={this.addNewSlotsRow}>
                                        <PlusIcon size="22" />
                                    </Button>
                                </OverlayTrigger> */}
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={<Tooltip id={"save-design-tooltip"}>Save</Tooltip>}>
                                    <Button onClick={this.saveSlots}>Save Updates</Button>
                                </OverlayTrigger>
                            </Col>
                        </HeaderRow>
                        {this.state.slotRows.map((slot) => { return this.createSlotRow(slot, true); })}
                    </Col>
                </Row>
            </>
        );
    }
}