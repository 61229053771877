import React, { ReactNode } from "react";
import { Form } from "react-bootstrap";

type FilterDropdownProperties = {
    filter: DropdownFilter,
    callback: (qsParam: Record<string, string>) => void
}

type FilterDropdownState = {
    value: string
}

export default class FilterDropdown extends React.Component<FilterDropdownProperties, FilterDropdownState> {

    constructor(props: FilterDropdownProperties) {
        super(props);

        this.state = {
            value: this.props.filter.defaultValue
        };
    }

    public reset(): void {
        this.setState({
            value: this.props.filter.defaultValue
        });
    }

    public handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            value: event.target.value
        });
        if (this.props.filter.qsParam) {
            const qsObj: Record<string, string> = {};
            if (event.target.value !== this.props.filter.defaultValue) {
                qsObj[this.props.filter.qsParam] = event.target.value;
            } else {
                qsObj[this.props.filter.qsParam] = "";
            }
            this.props.callback(qsObj);
        }
    }

    public render(): ReactNode {
        return (
            <Form.Control
                as="select"
                id={this.props.filter.id}
                title={this.props.filter.name}
                value={this.state.value}
                size="sm"
                onChange={this.handleOnChange}>

                {this.props.filter.values?.map((value, i) => {
                    return (
                        <option value={value} key={i}>
                            {value}
                        </option>
                    );
                })}
            </Form.Control>
        );
    }
}
