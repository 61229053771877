import React, { ReactNode } from "react";
import {
    Button, Col, Container, InputGroup,
    OverlayTrigger, Row, Tooltip, Form, Alert,
    Table
} from "react-bootstrap";
import { Copy as CopyIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import "rc-slider/assets/index.css";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment";

import * as constants from "../constants";
import { getGenres, getHostURL, getModelDomainInformation, getOperationalSlotInfo, makeSlotRequest, postModelDomainInformation } from "../utils/Requests";
import RandomField from "../components/fields/RandomField";
import { generateGuid } from "../utils/Utils";
import MetadataCarousel from "../components/MetadataCarousel";
import copy from "copy-to-clipboard";

const CarouselRow = styled(Row)`
    background-color: #282828;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
`;

const FieldName = styled(InputGroup.Text)`

`;

const FieldValue = styled(Form.Control)`
    padding: 0.375rem 0.225rem;
`;

const ParametersAlert = styled(Alert)`
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: 10;
    min-width: 200px;
    height: 42px;
`;

const MLTRow = styled(Row)`
    background-color: #282828;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    width: 100%;
`;

const MLTCol = styled(Col)`
    text-align: center;
`;

const SelectedEntityCol = styled(Col)`
    text-align: center;
    padding: 0;
`;

type ModelManagerState = {
    availableModelInfo: Array<Record<string, any>>,
    showSuccess: boolean,
    view: string,
    errorMsg: string,
    selectedPlaylistName: string,
    selectedPlaylistStatus: string,
    selectedPlaylistModel: string,
    selectedPlaylistVariant: string,
    selectedPlaylistOverlay: string,
    selectedPlaylistURL: string,
    userId: string,
    seedId: string,
    slotsInfo: Record<string, any>,
    availableVariants: Record<string,any>,
    availableOverlays: Record<string,any>,
    items: Array<Record<string,any>>,
    genreFilter: string,
    typenameFilter: string,
    ageSegmentFilter: string,
    genderSegmentFilter: string,
    locationSegmentFilter: string,
    genres: Array<string>
}

type ModelManagerProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

class ModelManager extends React.Component<ModelManagerProperties, ModelManagerState> {

    private OVERVIEW = "Overview";
    private NEW = "New";
    private EDIT = "Edit";

    private availableStatus = ["Live", "Test", "In Development"]

    constructor(props: ModelManagerProperties) {
        super(props);

        this.state = {
            availableModelInfo: [],
            showSuccess: false,
            view: this.OVERVIEW,
            errorMsg: "",
            selectedPlaylistName: "",
            selectedPlaylistStatus: "",
            selectedPlaylistModel: "",
            selectedPlaylistVariant: "",
            selectedPlaylistOverlay: "",
            selectedPlaylistURL: "",
            userId: "cold",
            seedId: "",
            slotsInfo: {},
            availableVariants: {},
            availableOverlays: {},
            items: [],
            genreFilter: "",
            typenameFilter: "",
            ageSegmentFilter: "",
            genderSegmentFilter: "",
            locationSegmentFilter: "",
            genres: []
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.requestModelDomainInfo();
        this.requestCustomerSlotInfo();
        this.getGenres();
    }

    private async requestModelDomainInfo(): Promise<void> {
        await getModelDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availableModelInfo: response
            });
        });
    }

    private async requestCustomerSlotInfo(): Promise<void> {
        await getOperationalSlotInfo(this.props.customerConfig.slug).then((response: Record<string, any>) => {
            let slotsInfoResponse: Record<string,any>[] = [];
            const slotsInfo: Record<string, any> = {};

            for (const slotKey in response["slots"]) {
                slotsInfoResponse = slotsInfoResponse.concat(response["slots"][slotKey]);
            }

            slotsInfoResponse.forEach((slot: Record<string, any>) => {
                // Skip Email RFY for EPIX as it doesn't fit this concept.
                if (slot["slotId"] !== "6e0a51f3-5651-4c1c-a2c3-8b6479e64a24") {
                    const variants = slot["parameters"]["variant"];
                    const overlays = slot["parameters"]["overlay"];

                    let variantInfo: Record<string,any>[] = [];
                    if (variants !== undefined) {
                        variantInfo = Object.values(variants["options"]).map((variant: any) => {
                            return {
                                "name": variant["displayName"],
                                "qsp": variant["qsp"]
                            };
                        });
                    }

                    let overlayInfo: Record<string,any>[] = [];
                    if (overlays !== undefined) {
                        overlayInfo = Object.values(overlays["options"]).map((overlay: any) => {
                            return {
                                "name": overlay["displayName"],
                                "qsp": overlay["qsp"]
                            };
                        });

                    }

                    slotsInfo[slot["slotId"]] = {
                        "id": slot["slotId"],
                        "name": slot["slotName"],
                        "type": slot["type"],
                        "userId": Object.keys(slot["parameters"]).includes("userId"),
                        "seedId": Object.keys(slot["parameters"]).includes("seedIds"),
                        "variants": variantInfo,
                        "overlays": overlayInfo
                    };
                }
            });

            this.setState({
                slotsInfo
            });
        });
    }


    private getGenres = async (): Promise<void> => {
        this.setState({
            genres: []
        });
        if (this.props.customerConfig) {
            await getGenres(this.props.customerConfig).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        const genresList = response.items[0]["genres"];
                        if (!genresList.includes("All Genres")) genresList.unshift("All Genres");
                        this.setState({
                            genres: genresList
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private switchToNew = (): void => {
        const firstSlot = Object.values(this.state.slotsInfo)[0];

        this.setState({
            view: this.NEW,
            selectedPlaylistModel: firstSlot["id"],
            selectedPlaylistName: "",
            availableVariants: firstSlot["variants"],
            availableOverlays: firstSlot["overlays"],
            selectedPlaylistVariant: firstSlot["variants"][0]["qsp"],
            selectedPlaylistOverlay: firstSlot["overlays"][0]["qsp"],
            selectedPlaylistStatus: ""
        }, this.setUrl);
    }

    private switchToOverview = (): void => {
        this.setState({
            availableModelInfo: [],
            showSuccess: false,
            view: this.OVERVIEW,
            errorMsg: "",
            selectedPlaylistName: "",
            selectedPlaylistStatus: "",
            selectedPlaylistModel: "",
            selectedPlaylistVariant: "",
            selectedPlaylistOverlay: "",
            selectedPlaylistURL: "",
            userId: "cold",
            seedId: "",
            slotsInfo: {},
            availableVariants: {},
            availableOverlays: {},
            genreFilter: "",
            typenameFilter: "",
            ageSegmentFilter: "",
            items: []
        }, this.reset);
    }

    private switchToEdit = (row: Record<string, any>): void => {

        let modelGenre = "";
        if ("genre" in row) {
            modelGenre = row["genre"];
        }

        let modelTypename = "";
        if ("typename" in row) {
            modelTypename = row["typename"];
        }

        this.setState({
            view: this.EDIT,
            selectedPlaylistModel: row["slot_id"],
            selectedPlaylistName: row["playlist_name"],
            selectedPlaylistVariant: row["variant"],
            selectedPlaylistOverlay: row["overlay"],
            selectedPlaylistStatus: row["status"],
            availableVariants: this.state.slotsInfo[row["slot_id"]]["variants"],
            availableOverlays: this.state.slotsInfo[row["slot_id"]]["overlays"],
            genreFilter: modelGenre,
            typenameFilter: modelTypename
        }, this.setUrl);
    }

    private setUrl = () => {
        const hostUrl = getHostURL(this.props.customerConfig);

        const slotId = this.state.selectedPlaylistModel;
        const slotInfo = this.state.slotsInfo[slotId];
        const variant = this.state.selectedPlaylistVariant;
        const overlay = this.state.selectedPlaylistOverlay;
        const genre = this.state.genreFilter;
        const typename = this.state.typenameFilter;

        let slotUrl = `${hostUrl}/slots/${slotId}/items?${variant}&${overlay}`;

        if (genre !== "") {
            slotUrl += `&genre=${genre}`;
        }

        if (typename !== "") {
            slotUrl += `&typename=${typename}`;
        }


        if (slotInfo["userId"]) {
            slotUrl += "&userId={userId}";
        }

        if (slotInfo["seedId"]) {
            slotUrl += "&seedIds={seedId}";
        }

        this.setState({
            selectedPlaylistURL: slotUrl
        }, this.makeModelRequest);
    }

    private setPlaylistName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedPlaylistName: event.currentTarget.value
        });
    }

    private setStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistStatus: event.currentTarget.value
        });
    }

    private setModel = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const slotId = event.currentTarget.value;
        const slotInfo = this.state.slotsInfo[slotId];
        this.setState({
            selectedPlaylistModel: slotId,
            availableVariants: slotInfo["variants"],
            availableOverlays: slotInfo["overlays"],
            selectedPlaylistVariant: slotInfo["variants"][0]["qsp"],
            selectedPlaylistOverlay: slotInfo["overlays"][0]["qsp"]
        }, this.setUrl);
    }

    private setVariant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistVariant: event.currentTarget.value
        }, this.setUrl);
    }

    private setOverlay = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistOverlay: event.currentTarget.value
        }, this.setUrl);
    }

    private setGenre = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let genreValue = event.currentTarget.value;

        if (genreValue === "All Genres") {
            genreValue = "";
        }

        this.setState({
            genreFilter: genreValue
        }, this.setUrl);
    }

    private setTypename = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let typenameValue = event.currentTarget.value;

        if (typenameValue === "All Types") {
            typenameValue = "";
        }

        this.setState({
            typenameFilter: typenameValue
        }, this.setUrl);
    }

    private setAgeSegment = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let sapSegmentValue = event.currentTarget.value;

        if (sapSegmentValue === "All Ages") {
            sapSegmentValue = "";
        }

        this.setState({
            ageSegmentFilter: sapSegmentValue
        }, this.setUrl);
    }

    private setGenderSegment = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let sapSegmentValue = event.currentTarget.value;

        if (sapSegmentValue === "All Genders") {
            sapSegmentValue = "";
        }

        this.setState({
            genderSegmentFilter: sapSegmentValue
        }, this.setUrl);
    }

    private setLocationSegment = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let sapSegmentValue = event.currentTarget.value;

        if (sapSegmentValue === "All Countries") {
            sapSegmentValue = "";
        }

        this.setState({
            locationSegmentFilter: sapSegmentValue
        }, this.setUrl);
    }

    private setSeedId = (callbackValue: string, submit: boolean) => {
        this.setState({
            seedId: callbackValue
        }, this.makeModelRequest);
    }

    private setUserId = (callbackValue: string, submit: boolean) => {
        this.setState({
            userId: callbackValue
        }, this.makeModelRequest);
    }

    private copyURLToClipboard = (url: string) => {
        copy(url);
    }

    private copyDynamicURLToClipboard = () => {
        copy(this.state.selectedPlaylistURL);
    }

    private exportToCsv = () => {
        const availableModels = this.state.availableModelInfo;

        availableModels.forEach((model) => {
            model["created_by"] = model["audit_info"]["created_by"];
            model["date_created"] = model["audit_info"]["date_created"];
            model["updated_by"] = model["audit_info"]["updated_by"];
            model["date_updated"] = model["audit_info"]["date_updated"];

            model["model_type"] = model["model_type"].toUpperCase();
            model["overlay"] = model["overlay"].split("=")[1];
            model["variant"] = model["variant"].split("=")[1];

            delete model["audit_info"];
        });

        const timestamp = moment(new Date().toUTCString()).format(constants.YYMMDD_FORMAT);
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: `available_models_${this.props.customerConfig.slug}_${timestamp}`});
        const csv = generateCsv(csvConfig)(availableModels);
        download(csvConfig)(csv);
    }

    private savePlaylist = () => {
        const modelBody: Record<string, any> = {
            "playlist_name": this.state.selectedPlaylistName,
            "model_type": this.state.slotsInfo[this.state.selectedPlaylistModel]["type"],
            "overlay": this.state.selectedPlaylistOverlay,
            "variant": this.state.selectedPlaylistVariant,
            "slot_url": this.state.selectedPlaylistURL,
            "status": this.state.selectedPlaylistStatus,
            "slot_id": this.state.selectedPlaylistModel,
            "audit_info": {
                "created_by": this.props.username,
                "updated_by": this.props.username
            }
        };

        if (this.state.genreFilter !== "") {
            modelBody["genre"] = this.state.genreFilter;
        }

        if (this.state.typenameFilter !== "") {
            modelBody["typename"] = this.state.typenameFilter;
        }

        try {
            postModelDomainInformation(this.props.customerConfig, modelBody).then(() => {
                this.setState({
                    showSuccess: true
                });
            });

        } catch (error) {
            this.setState({
                errorMsg: "Error saving playlist"
            });
        }

    }

    private async makeModelRequest() {
        if (this.state.selectedPlaylistURL !== "") {
            try {
                let modelRequestURL = this.state.selectedPlaylistURL.replace("{userId}", this.state.userId).replace("{seedId}", this.state.seedId);
                modelRequestURL += "&ignore=portal";

                const itemsRequest = await makeSlotRequest(modelRequestURL, true);

                itemsRequest.json().then(async (responseJson) => {
                    this.setState({
                        items: responseJson["items"]
                    });
                });
            } catch (error) {
                //Whatever.
            }
        }
    }

    private renderContent() {
        switch (this.state.view) {
            case this.OVERVIEW:
                return (
                    <div>
                        <>
                        <Row>
                            <Table striped bordered responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Playlist Name</th>
                                        <th>Type</th>
                                        <th>Set-up</th>
                                        <th>Status</th>
                                        <th>Slot URL</th>
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                {this.state.availableModelInfo.length > 0 ? (<tbody>
                                    {this.state.availableModelInfo.map(row => (
                                        <tr key={generateGuid()}>
                                            <td key={row.playlist_name + "name"}>
                                                {row.playlist_name}
                                            </td>
                                            <td key={row.playlist_name + "type"}>
                                                {row.model_type.replaceAll("_", " ").toUpperCase()}
                                            </td>
                                            <td key={row.playlist_name + "set-up"}>
                                                {"Variant: " + row.variant.split("=")[1]}<br/>{"Overlay: " + row.overlay.split("=")[1]}
                                            </td>
                                            <td key={row.playlist_name + "status"}>
                                                {row.status}
                                            </td>
                                            <td key={row.playlist_name + "slotUrl"}>
                                                {row.slot_url}
                                            </td>
                                            {/* <td key={row.playlist_name + "audit"}>
                                                {"Created By " + row.audit_info.created_by}
                                            </td> */}
                                            <td key={row.playlist_name + "copy"}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id={row.playlist_name + "url-tooltip"}>Copy URL To Clipboard</Tooltip>}>
                                                    <Button variant="secondary" onClick={() => {this.copyURLToClipboard(row.slot_url);}}><CopyIcon size={18}/></Button>
                                                </OverlayTrigger>
                                            </td>
                                            <td key={row.playlist_name + "edit"}>
                                                <Button onClick={() => {this.switchToEdit(row);}}>Edit</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>) :
                                (
                                    <tbody />
                                )}
                            </Table>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Button onClick={this.exportToCsv}>Export to CSV</Button>
                            <Button style={{marginLeft: "5px"}} onClick={this.switchToNew}>
                                Add new model playlist
                            </Button>
                        </Row>
                        </>
                    </div>
                );
            case this.NEW:
            case this.EDIT:
                return <>
                    <Row style={{paddingTop: "20px"}}>
                        <Col md="8">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Playlist Name</FieldName>
                                </InputGroup.Prepend>
                                <FieldValue
                                    id="playlistNameInput"
                                    type="string"
                                    placeholder="Playlist Name"
                                    value={this.state.selectedPlaylistName}
                                    onChange={this.setPlaylistName}
                                />
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Status</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-status"
                                    title="Status"
                                    onChange={this.setStatus}
                                    value={this.state.selectedPlaylistStatus}
                                >
                                    {
                                        this.availableStatus.map((status, i) => {
                                            return (
                                                <option key={`${i}`} value={`${status}`}>
                                                    {status}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Model Selection</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-model"
                                    title="Model"
                                    onChange={this.setModel}
                                    value={this.state.selectedPlaylistModel}
                                >
                                    {Object.values(this.state.slotsInfo).map((slot: Record<string, any>) => {
                                        return <option value={slot["id"]}>{slot["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Variant</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-variant"
                                    title="Variant"
                                    onChange={this.setVariant}
                                    value={this.state.selectedPlaylistVariant}
                                >
                                    {Object.values(this.state.availableVariants).map((variant: Record<string, any>) => {
                                        return <option value={variant["qsp"]}>{variant["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Overlay</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-overlay"
                                    title="Overlay"
                                    value={this.state.selectedPlaylistOverlay}
                                    onChange={this.setOverlay}
                                >
                                    {Object.values(this.state.availableOverlays).map((overlay: Record<string, any>) => {
                                        return <option value={overlay["qsp"]}>{overlay["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Genre Filter</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-genre"
                                    title="Genre"
                                    onChange={this.setGenre}
                                    value={this.state.genreFilter}
                                >
                                    {Object.values(this.state.genres).map((genre: string) => {
                                        return <option value={genre}>{genre}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Typename Filter</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-typename"
                                    title="Typename"
                                    value={this.state.typenameFilter}
                                    onChange={this.setTypename}
                                >
                                    <option value="All Types">All Types</option>
                                    <option value="movie">Movie</option>
                                    <option value="radioseries">Radio Series</option>
                                    <option value="tvseries">TV Series</option>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    {this.props.customerConfig.slug === "rtve" && <Row style={{paddingTop: "10px"}}>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Age Segment Filter</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-age-segment"
                                    title="Age Segment"
                                    value={this.state.ageSegmentFilter}
                                    onChange={this.setAgeSegment}
                                >
                                    <option value="All Ages">All Segments</option>
                                    <option value="18to24" disabled>Ages 18 to 24</option>
                                    <option value="25to34" disabled>Ages 25 to 34</option>
                                    <option value="35to44" disabled>Ages 35 to 44</option>
                                    <option value="45to54" disabled>Ages 45 to 54</option>
                                    <option value="55to64" disabled>Ages 55 to 64</option>
                                    <option value="65over" disabled>Ages 65 or over</option>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Gender Filter</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-age-segment"
                                    title="Gender Segment"
                                    value={this.state.genderSegmentFilter}
                                    onChange={this.setGenderSegment}
                                >
                                    <option value="All Genders">All Genders</option>
                                    <option value="m" disabled>Male</option>
                                    <option value="f" disabled>Female</option>
                                    <option value="u" disabled>Undisclosed</option>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Country Filter</FieldName>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-location-segment"
                                    title="Country Segment"
                                    value={this.state.locationSegmentFilter}
                                    onChange={this.setLocationSegment}
                                >
                                    <option value="All Countries">All Countries</option>
                                    <option value="algeria" disabled>Algeria</option>
                                    <option value="andorra" disabled>Andorra</option>
                                    <option value="argentina" disabled>Argentina</option>
                                    <option value="belgium" disabled>Belgium</option>
                                    <option value="brazil" disabled>Brazil</option>
                                    <option value="canada" disabled>Canada</option>
                                    <option value="chile" disabled>Chile</option>
                                    <option value="colombia" disabled>Colombia</option>
                                    <option value="costa_rica" disabled>Costa Rica</option>
                                    <option value="cuba" disabled>Cuba</option>
                                    <option value="ecuador" disabled>Ecuador</option>
                                    <option value="ethiopia" disabled>Ethiopia</option>
                                    <option value="finland" disabled>Finland</option>
                                    <option value="france" disabled>France</option>
                                    <option value="germany" disabled>Germany</option>
                                    <option value="guatemala" disabled>Guatemala</option>
                                    <option value="hong_kong" disabled>Hong Kong</option>
                                    <option value="ireland" disabled>Ireland</option>
                                    <option value="israel" disabled>Israel</option>
                                    <option value="italy" disabled>Italy</option>
                                    <option value="mexico" disabled>Mexico</option>
                                    <option value="netherlands" disabled>Netherlands</option>
                                    <option value="panama" disabled>Panama</option>
                                    <option value="peru" disabled>Peru</option>
                                    <option value="puerto_rico" disabled>Puerto Rico</option>
                                    <option value="romania" disabled>Romania</option>
                                    <option value="singapore" disabled>Singapore</option>
                                    <option value="spain" disabled>Spain</option>
                                    <option value="sweden" disabled>Sweden</option>
                                    <option value="switzerland" disabled>Switzerland</option>
                                    <option value="turkey" disabled>Turkey</option>
                                    <option value="united_kingdom" disabled>United Kingdom</option>
                                    <option value="united_states" disabled>United States</option>
                                    <option value="uruguay" disabled>Uruguay</option>
                                    <option value="venezuela" disabled>Venezuela</option>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>}
                    <Row style={{paddingTop: "10px"}}>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <FieldName>Slot URL</FieldName>
                                </InputGroup.Prepend>
                                <FieldValue
                                    id="slotUrlOutput"
                                    type="string"
                                    placeholder="Slot URL"
                                    readOnly
                                    value={this.state.selectedPlaylistURL}
                                />
                                <InputGroup.Append>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="search-tooltip">Copy URL</Tooltip>}>
                                        <Button variant="primary" type="button" onClick={this.copyDynamicURLToClipboard}>
                                            <CopyIcon size={18}/>
                                        </Button>
                                    </OverlayTrigger>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.USER}
                                includeIcon
                                initialValue={this.state.userId}
                                onChangeCallback={this.setUserId}
                            />
                        </Col>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.SEED}
                                includeIcon
                                initialValue={this.state.seedId}
                                onChangeCallback={this.setSeedId}
                            />
                        </Col>
                    </Row>
                    <CarouselRow style={{paddingTop: "10px"}}>
                        <MLTCol>
                            <h5 className="mx-auto">Results Preview</h5>
                            {this.renderPreview()}
                        </MLTCol>
                    </CarouselRow>
                    <Row style={{paddingTop: "10px"}} className="d-flex justify-content-end">
                        <Col className="d-flex justify-content-end">
                            <Button onClick={this.savePlaylist}>Save Playlist</Button>
                            <Button variant="secondary" style={{marginLeft: "5px"}} onClick={this.switchToOverview}>Return to Overview</Button>
                        </Col>
                    </Row>
                </>;
            default:
                return null;
        }
    }


    private renderPreview(): ReactNode {
        if (!this.state.items) {
            return null;
        }

        return (
            <MLTRow>
                <SelectedEntityCol md>
                    <MetadataCarousel
                        key={JSON.stringify(this.state.items)}
                        items={this.state.items}
                        customer={this.props.customerConfig}
                        username={this.props.username}
                        groups={this.props.groups}
                        lessItems
                        hasEntity={true}
                        hideMissingText
                        itemText
                    />
                </SelectedEntityCol>
            </MLTRow>
        );
    }


    public render(): ReactNode {
        return (
            <>
                <ParametersAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Model playlist saved
                </ParametersAlert>
                <Container className="mw-100" key="model-manager" style={{paddingTop: "15px"}}>
                    <Row className="d-flex justify-content-center">
                        <Col md="11">
                            <h4>Model Manager - {this.state.view}</h4>
                            {this.renderContent()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ModelManager;