import "rc-slider/assets/index.css";

import Slider from "rc-slider";
import React, { ReactNode } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../../config/Branding";

type RangeSliderProperties = {
    filter: RangeSliderFilter,
    callback: (qsParam: Record<string, string>) => void
}

type RangeSliderState = {
    value: number[]
}

const StyledSlider = styled(Slider)`
    right: 5px;
`;

const Label = styled(Col)`
    text-align: left;
`;

const handleStyle = {
    border: `solid 2px ${Branding.primary}`
};

const dotStyle = {
    borderColor: `${Branding.primary}`
};

const trackStyle = {
    backgroundColor: `${Branding.primary}`
};

const RangeWithToolTip = StyledSlider;

export default class RangeSlider extends React.Component<RangeSliderProperties, RangeSliderState> {

    constructor(props: RangeSliderProperties) {
        super(props);

        const { filter } = props;
        const { min, max } = filter;

        this.state = {
            value: [min, max]
        };
    }

    public reset(): void {
        const { filter } = this.props;
        const { min, max } = filter;

        this.setState({
            value: [min, max]
        });
    }

    private onSliderChange = (value: number[]): void => {
        this.setState({ value });
    }

    public onSliderAfterChange = (value: number[]): void => {
        const { filter, callback } = this.props;
        const { qsMinParam, qsMaxParam, useMarksAsValue, marks } = filter;

        if (qsMinParam && qsMaxParam) {
            const qsObj: Record<string, string> = useMarksAsValue
                ? {
                    [qsMinParam]: marks[value[0]].label,
                    [qsMaxParam]: marks[value[1]].label
                }
                : {
                    [qsMinParam]: value[0].toString(),
                    [qsMaxParam]: value[1].toString()
                };

            callback(qsObj);
        }
    };

    public render(): ReactNode {
        const { filter } = this.props;
        const { value } = this.state;

        return (
            <>
                <Label xs="3" sm="3">{filter.name}</Label>
                <Col xs="9" sm="9">
                    {filter.showToolTip ? (
                        <RangeWithToolTip
                            range
                            min={filter.min}
                            max={filter.max}
                            value={value}
                            allowCross={true}
                            marks={filter.marks}
                            onChange={this.onSliderChange}
                            onAfterChange={this.onSliderAfterChange}
                            step={filter.steps}
                            trackStyle={[trackStyle]}
                            handleStyle={[handleStyle, handleStyle]}
                            dotStyle={dotStyle}
                        />
                    ) : (
                        <StyledSlider
                            range
                            min={filter.min}
                            max={filter.max}
                            value={value}
                            allowCross={true}
                            marks={filter.marks}
                            onChange={this.onSliderChange}
                            onAfterChange={this.onSliderAfterChange}
                            step={filter.steps}
                            trackStyle={[trackStyle]}
                            handleStyle={[handleStyle, handleStyle]}
                            dotStyle={dotStyle}
                        />
                    )}
                </Col>
            </>
        );
    }
}