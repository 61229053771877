import React, { ReactNode } from "react";
import Slider from "rc-slider";
import { Col, Form, Image, Row } from "react-bootstrap";
import { XCircleFill as RemoveIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import * as utils from "../../utils/Utils";
import Branding from "../../config/Branding";

const ResultsRow = styled(Row)`
    margin: 0
`;

const ResultCol = styled(Col)`
    padding-right: 0px;
`;

const ResultImage = styled(Image)`
`;

const ResultTitle = styled.div`
    text-align: center;
    @media (min-width: 768px) {
        max-width: 200px;
    }
`;

const Result = styled.div`
    max-width: 200px;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 5px;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
    color: white;
    background: black;
    border-radius: 10px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    box-shadow: 0 0 10px black;
    position: absolute;
    top: 10px;
    right: 10px;
`;

type AssociationGridProperties = {
    items: Array<SearchItem>,
    removeCallback?: (item: SearchItem) => void,
    itemBoosts?: Record<string, number>,
    showBoost?: boolean
}

type AssociationGridState = {
    itemBoosts: Record<string, number>
}

const handleStyle = {
    border: `solid 2px ${Branding.primary}`
};

const dotStyle = {
    borderColor: `${Branding.primary}`
};

const trackStyle = {
    backgroundColor: `${Branding.primary}`
};

const boostMapping: Record<number, string> = {
    0: "No Boost",
    1: "Light Boost",
    2: "Medium Boost",
    3: "Heavy Boost",
    4: "Maximum Boost"
};

export default class AssociationGrid extends React.Component<AssociationGridProperties, AssociationGridState> {
    constructor(props: AssociationGridProperties) {
        super(props);

        this.state = {
            itemBoosts: this.props.itemBoosts || {}
        };
    }

    private removeAssociation = (item: SearchItem) => {
        const { removeCallback } = this.props;
        if (removeCallback) {
            removeCallback(item);
        }
    };

    private boostItem = (item: SearchItem, value: number): void => {
        const { itemBoosts } = this.state;
        itemBoosts[item.id] = value;
        this.setState({ itemBoosts });
    };

    public clearBoosts(): void {
        this.setState({ itemBoosts: {} });
    }

    public getItemBoosts(): Record<string, number> {
        return this.state.itemBoosts;
    }

    private convertItemsToGrid(): ReactNode {
        const { items, removeCallback, showBoost } = this.props;

        return items.map((item) => (
            <ResultCol md={{ span: "auto" }} key={item.id}>
                <Result>
                    {removeCallback && (
                        <StyledRemoveIcon onClick={() => this.removeAssociation(item)} />
                    )}
                    <ResultImage
                        src={item.image}
                        rounded
                        width={200}
                        className="img-fluid"
                        onError={(event) => utils.getPlaceholderImage(event, item)}
                    />
                    <ResultTitle>{item.name}</ResultTitle>
                    {showBoost && (
                        <Form.Group controlId="formBasicRange">
                            <Slider
                                min={0}
                                max={4}
                                value={this.state.itemBoosts[item.id] || 0}
                                onChange={(value: number) => this.boostItem(item, value)}
                                trackStyle={[trackStyle]}
                                handleStyle={[handleStyle]}
                                dotStyle={dotStyle}
                            />
                            <Form.Label>
                                {boostMapping[this.state.itemBoosts[item.id] || 0]}
                            </Form.Label>
                        </Form.Group>
                    )}
                </Result>
            </ResultCol>
        ));
    }

    public render(): ReactNode {
        const { items } = this.props;

        return (
            <ResultsRow className="justify-content-center">
                {items.length > 0 && this.convertItemsToGrid()}
                {items.length === 0 && <p><br />No items selected</p>}
            </ResultsRow>
        );
    }
}