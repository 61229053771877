import React, { ReactNode } from "react";
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, OverlayTrigger, Spinner, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import {
    Plus as PlusIcon,
    X as CrossIcon,
    FileEarmarkArrowDownFill as SaveIcon,
    PersonCircle as UserIcon,
    FileEarmarkRichtextFill as PageIcon,
    Tv as SeedIcon,
    CaretUpFill as UpIcon,
    CaretDownFill as DownIcon
} from "react-bootstrap-icons";

import { ComposerElementTypes, UserClusterTypes } from "../../enums";
import { arrayMove, generateGuid } from "../../utils/Utils";
import { getSlotsForCustomer } from "../../utils/Requests";

const ElementRow = styled(Row)`
    background-color: #282828;
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 5px;
`;

const HeaderRow = styled(Row)`
    border: thin solid #282828;
    padding: 10px 5px;
    margin-top: 10px;
    margin-left: -15px;
    margin-bottom: 20px;
    margin-right: -15px;
    border-radius: 3px;
`;

const ComposerForm = styled(Form)`
    width: 100%;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

const AssocAlert = styled(Alert)`
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

const ArrowButton = styled(Button)`
    padding: 3px;
`;

const DeleteButton = styled(Button)`
    padding: 6px;
`;

const HintsList = styled.ul`
    margin-bottom: 0
`;

type DesignGridState = {
    elementRows: ComposerElement[],
    designCustomer: string,
    designPageName: string,
    designUserId: string,
    designSeedId: string,
    elementCount: number,
    refresh: boolean,
    showSuccess: boolean,
    showError: boolean,
    slots: Slot[],
    slotId: string,
    slotLoading: boolean,
    error: unknown
}

type DesignGridProperties = {
    groups: string[],
    elements: ComposerElement[],
    fppElements: ComposerElement[],
    customerConfig: CustomerConfig,
    pageName?: string,
    userId?: string,
    seedId?: string,
    seedItem?: Record<string, any>,
    saveCallback: (customer: string, pageName: string, userId: string, seedId: string, elements: ComposerElement[]) => Promise<void>
}

export default class DesignGrid extends React.Component<DesignGridProperties, DesignGridState> {

    constructor(props: DesignGridProperties) {
        super(props);

        this.state = {
            designCustomer: this.props.customerConfig.name,
            designPageName: this.props.pageName || "",
            designUserId: this.props.userId || "",
            designSeedId: this.props.seedId || "",
            elementRows: this.props.elements,
            elementCount: this.props.elements.length,
            refresh: false,
            showSuccess: false,
            showError: false,
            slots: [],
            slotId: "",
            slotLoading: false,
            error: null
        };
    }

    public componentDidMount(): void {
        if (this.state.elementRows.length === 0) this.addNewElementRow();
        this.getSlots();
    }

    private async getSlots(): Promise<void> {
        try {
            this.setState({ slotLoading: true });
            const slots = await getSlotsForCustomer(this.props.customerConfig);
            this.setState({ slots, slotLoading: false });
        } catch (error) {
            this.setState({ slotLoading: false, error });
        }
    }

    private setDesignUserId = (element: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            designUserId: element.currentTarget.value
        });
    }

    private setDesignSeedId = (element: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            designSeedId: element.currentTarget.value
        });
    }

    private setDesignPageName = (element: React.ChangeEvent<HTMLInputElement>): void => {
        const pageName = element.currentTarget.value;

        this.setState({
            designPageName: pageName
        });
    }

    private saveDesign = (): void => {
        this.setState({
            refresh: true
        });
        this.props.saveCallback(
            this.state.designCustomer,
            this.state.designPageName,
            this.state.designUserId,
            this.state.designSeedId,
            this.state.elementRows
        );
        setTimeout(() => {
            this.setState({
                refresh: false,
                showSuccess: true
            });
        }, 1500);
        setTimeout(() => {
            this.setState({
                showSuccess: false
            });
        }, 4000);
    }

    private addNewElementRow = (): void => {
        const elements = this.state.elementRows;
        elements.push({
            key: `${generateGuid()}`,
            title: "",
            type: ComposerElementTypes.SlotCarousel,
            slotUrl: "",
            seedIds: ""
        });

        this.setState({
            elementRows: elements,
            elementCount: this.state.elementCount + 1
        });
    }

    private setElementUserId(
        element: ComposerElement,
        event: React.ChangeEvent<HTMLInputElement>): void {
        const userId = event.currentTarget.value;

        this.setElementField(element, "username", event);

        this.setState({
            designUserId: userId
        });
    }

    private setElementField(
        element: ComposerElement,
        field: "key" | "title" | "type" | "slotUrl" | "slotId" | "seedIds" | "customer" | "username" | "cluster" | "spacer",
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) {

        const elements = this.state.elementRows;
        const eleIndex = elements.findIndex(obj => obj.key === element.key);

        if (field === "slotId") {
            const slotId = event.currentTarget.value;
            const slotUrl = `https://${this.props.customerConfig.slug}.[ENVIRONMENT].thefilter.com/v0/slots/${slotId}/items?_debug_metadata=true&userID=[USER_ID]&seedIds=[SEED_ID]`;

            elements[eleIndex][field] = slotId;
            elements[eleIndex]["slotUrl"] = slotUrl;
        } else {
            elements[eleIndex][field] = event.currentTarget.value;
        }

        this.setState({
            elementRows: elements
        });
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    private extractSlotId(url: string): string | null {
        const regex = /slots\/(.*?)\/items\?/g;
        const match = regex.exec(url);

        if (match && match[1]) {
            return match[1];
        }

        return null;
    }


    private createElementRow(element: ComposerElement, enabled: boolean) {
        const elementAtTop = this.elementAtEdge(element.key, true);
        const elementAtBottom = this.elementAtEdge(element.key, false);
        const slotId = this.extractSlotId(element.slotUrl);

        const availableSlots = this.state.slots.sort((a, b) => {
            return a.slotName.localeCompare(b.slotName);
        }).map((slot) => {
            return (
                <option value={slot.slotId} key={slot.slotId}>
                    {slot.slotName} ({slot.slotId})
                </option>
            );
        });

        return (
            <ElementRow key={element.key}>
                <ComposerForm inline onSubmit={(event: Event) => { event.preventDefault(); }}>
                    <Col md={10}>
                        <Form.Row>
                            <Col md={2}>
                                <Form.Control
                                    as="select"
                                    title="Type"
                                    value={element.type}
                                    disabled={!enabled}
                                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "type", value); }}
                                    style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem", marginRight: "10px", width: "100%" }}>
                                    <option value={ComposerElementTypes.SlotCarousel} key="type-slot-carousel">Slot Carousel</option>
                                    <option value={ComposerElementTypes.SlotLargeCarousel} key="type-slot-large-carousel">Slot Large Carousel</option>
                                    <option value={ComposerElementTypes.SlotHero} key="type-slot-hero">Slot Hero</option>
                                    <option value={ComposerElementTypes.Carousel} key="type-carousel">Carousel</option>
                                    <option value={ComposerElementTypes.LargeCarousel} key="type-large-carousel">Large Carousel</option>
                                    <option value={ComposerElementTypes.Hero} key="type-hero">Hero</option>
                                    <option value={ComposerElementTypes.FixedCarousel} key="type-fixed-carousel">Fixed Carousel</option>
                                    <option value={ComposerElementTypes.FixedLargeCarousel} key="type-fixed-large-carousel">Fixed Large Carousel</option>
                                    <option value={ComposerElementTypes.FixedHero} key="type-fixed-hero">Fixed Hero</option>
                                    <option value={ComposerElementTypes.FixedUserCarousel} key="type-fixed-user-carousel">Fixed User Carousel</option>
                                    <option value={ComposerElementTypes.FullPagePersonalisation} key="type-full-page">Full Page</option>
                                    <option value={ComposerElementTypes.Spacer} key="type-spacer">Spacer</option>
                                </Form.Control>
                            </Col>
                            {element.type !== ComposerElementTypes.Spacer && element.type !== ComposerElementTypes.FullPagePersonalisation &&
                                <Col md={3}>
                                    <Form.Control
                                        style={{ marginRight: "10px", width: "100%" }}
                                        type="text"
                                        placeholder="Title"
                                        value={element.title}
                                        disabled={!enabled}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "title", value); }}
                                    />
                                </Col>
                            }
                            {(element.type === ComposerElementTypes.Carousel || element.type === ComposerElementTypes.Hero ||
                                element.type === ComposerElementTypes.LargeCarousel) &&
                                <Col md={7}>
                                    <Form.Control
                                        style={{ marginRight: "10px", width: "100%" }}
                                        type="text"
                                        placeholder="Slot Url"
                                        value={element.slotUrl}
                                        disabled={!enabled}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "slotUrl", value); }}
                                    />
                                </Col>
                            }
                            {(element.type === ComposerElementTypes.SlotCarousel || element.type === ComposerElementTypes.SlotHero ||
                                element.type === ComposerElementTypes.SlotLargeCarousel) &&
                                <Col md={7}>
                                    <Form.Control
                                        as="select"
                                        id="dropdown-slot"
                                        title="Slot"
                                        style={{ marginRight: "10px", width: "100%" }}
                                        value={element.slotId || slotId || availableSlots[0]}
                                        onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
                                            this.setElementField(element, "slotId", value);
                                        }}
                                    >
                                        {availableSlots}
                                    </Form.Control>
                                </Col>
                            }
                            {(element.type === ComposerElementTypes.FullPagePersonalisation) &&
                                <Col md={10}>
                                    <Form.Control
                                        style={{ marginRight: "10px", width: "100%" }}
                                        type="text"
                                        placeholder="Recommendations Url"
                                        value={element.slotUrl}
                                        disabled={!enabled}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "slotUrl", value); }}
                                    />
                                </Col>
                            }

                            {(element.type === ComposerElementTypes.FixedCarousel || element.type === ComposerElementTypes.FixedHero ||
                                element.type === ComposerElementTypes.FixedLargeCarousel || element.type === ComposerElementTypes.FPPCarousel) &&
                                <Col md={7}>
                                    <Form.Control
                                        style={{ marginRight: "10px", width: "100%" }}
                                        type="text"
                                        placeholder="Seed IDs"
                                        value={element.seedIds}
                                        disabled={!enabled}
                                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "seedIds", value); }}
                                    />
                                </Col>
                            }
                            {(element.type === ComposerElementTypes.FixedUserCarousel) &&
                                <>
                                    <Col md={2}>
                                        <Form.Control
                                            style={{ marginRight: "10px", width: "100%" }}
                                            type="text"
                                            placeholder="Username"
                                            value={this.state.designUserId}
                                            disabled={!enabled}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.setElementUserId(element, event); }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control
                                            as="select"
                                            title="Cluster"
                                            value={element.cluster}
                                            disabled={!enabled}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "cluster", value); }}
                                            style={{ padding: "0.375rem 0.375rem 0.375rem 0.1rem", marginRight: "10px", width: "100%" }}>
                                            <option disabled selected>Select a cluster...</option>
                                            <option value={UserClusterTypes.movie} key="type-movie">{UserClusterTypes.movie}</option>
                                            <option value={UserClusterTypes.tvseries} key="type-tvseries">{UserClusterTypes.tvseries}</option>
                                            <option value={UserClusterTypes.action} key="type-action">{UserClusterTypes.action}</option>
                                            <option value={UserClusterTypes.documentary} key="type-documentary">{UserClusterTypes.documentary}</option>
                                            <option value={UserClusterTypes.drama} key="type-drama">{UserClusterTypes.drama}</option>
                                            <option value={UserClusterTypes.family} key="type-family">{UserClusterTypes.family}</option>
                                            <option value={UserClusterTypes.mixed} key="type-mixed">{UserClusterTypes.mixed}</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Control
                                            style={{ marginRight: "10px", width: "100%" }}
                                            type="text"
                                            placeholder="Seed IDs"
                                            value={element.seedIds}
                                            disabled={!enabled}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => { this.setElementField(element, "seedIds", value); }}
                                        />
                                    </Col>
                                </>
                            }
                        </Form.Row>
                    </Col>
                    {enabled &&
                        <Col md={2} className="d-flex justify-content-end">
                            <ButtonGroup style={{ marginRight: "10px" }}>
                                <ArrowButton
                                    variant="secondary"
                                    disabled={elementAtTop}
                                    onClick={() => { this.moveElement(element.key, true); }}>
                                    <UpIcon size="20" />
                                </ArrowButton>
                                <ArrowButton
                                    variant="secondary"
                                    disabled={elementAtBottom}
                                    onClick={() => { this.moveElement(element.key, false); }}>
                                    <DownIcon size="20" />
                                </ArrowButton>
                            </ButtonGroup>

                            <DeleteButton disabled={this.state.elementRows.length === 1} variant="danger" onClick={() => { this.deleteElement(element.key); }}>
                                <CrossIcon size="22" />
                            </DeleteButton>
                        </Col>
                    }
                </ComposerForm>
            </ElementRow>
        );
    }

    private elementAtEdge(key: string, top: boolean): boolean {
        const elements = this.state.elementRows;
        const position = elements.findIndex(obj => obj.key === key);

        return top ? position === 0 : position === elements.length - 1;
    }

    private moveElement(key: string, up: boolean): void {
        let elements = this.state.elementRows;
        const position = elements.findIndex(obj => obj.key === key);
        const newPosition = up ? position - 1 : position + 1;
        elements = arrayMove(elements, position, newPosition);

        this.setState({
            elementRows: elements
        });
    }

    private deleteElement(key: string): void {
        let elements = this.state.elementRows;
        elements = elements.filter(obj => obj.key !== key);

        this.setState({
            elementRows: elements
        });
    }

    private renderSeedItemName() {
        return (
            this.props.seedItem && (
                <>
                    <b style={{ marginLeft: "5px" }}>Name:&nbsp;</b>
                    {this.props.seedItem.name}
                </>
            )
        );
    }

    public render(): ReactNode {
        return (
            <>
                {this.state.refresh && <SpinnerDiv>
                    <Spinner animation="border" style={{ color: "white" }} />
                </SpinnerDiv>}

                <AssocAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Design saved
                </AssocAlert>
                <AssocAlert
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error saving design
                </AssocAlert>
                <Row style={{ opacity: this.state.refresh ? 0.5 : 1 }}>
                    <Col md={{ span: 10, offset: 1 }}>
                        <HeaderRow>
                            <Col md={10}>
                                <Form inline onSubmit={(event) => { event.preventDefault(); }}>
                                    <Form.Group style={{ marginBottom: 0, marginRight: "10px" }}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="user-tooltip">Page Name</Tooltip>}
                                                >
                                                    <InputGroup.Text><PageIcon size="22" /></InputGroup.Text>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                style={{ padding: "0.375rem 0.375rem", "height": "37px" }}
                                                type="text"
                                                placeholder="PageName"
                                                value={this.state.designPageName}
                                                onChange={this.setDesignPageName}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: 0, marginRight: "10px" }}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="user-tooltip">User</Tooltip>}
                                                >
                                                    <InputGroup.Text><UserIcon size="22" /></InputGroup.Text>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                style={{ padding: "0.375rem 0.375rem", "height": "37px" }}
                                                type="text"
                                                placeholder="User ID"
                                                value={this.state.designUserId}
                                                onChange={this.setDesignUserId}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: 0, marginRight: "10px" }}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="seed-tooltip">Seed</Tooltip>}
                                                >
                                                    <InputGroup.Text><SeedIcon size="22" /></InputGroup.Text>
                                                </OverlayTrigger>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                style={{ padding: "0.375rem 0.375rem", "height": "37px" }}
                                                type="text"
                                                placeholder="Seed ID"
                                                value={this.state.designSeedId}
                                                onChange={this.setDesignSeedId}
                                            />
                                        </InputGroup>
                                        {this.renderSeedItemName()}
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col md={2} className="d-flex justify-content-end">
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={<Tooltip id={"add-row-tooltip"}>Add New Row</Tooltip>}>
                                    <Button variant="secondary" style={{ marginRight: "10px" }} onClick={this.addNewElementRow}>
                                        <PlusIcon size="22" />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={<Tooltip id={"save-design-tooltip"}>Save</Tooltip>}>
                                    <Button onClick={this.saveDesign}>
                                        <SaveIcon size="22" />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </HeaderRow>
                        <Row>
                            <Col md={12} style={{ paddingLeft: 0 }}>
                                <Alert style={{ padding: "0.5rem 1.0rem", width: "max-content" }} variant={"secondary"}>
                                    <HintsList>
                                        <li>Use the [ENVIRONMENT] placeholder in a URL to support pages across environments</li>
                                        <li>Use the [SEED_ID] placeholder in a URL to support a dynamic seed ID</li>
                                        <li>Use the [SEED_TITLE] placeholder in a title to replace it with a looked up seed title, e.g. for a Because You Watched slot</li>
                                        <li>Use the [USER_ID] placeholder in a URL to support a dynamic user ID</li>
                                    </HintsList>
                                </Alert>
                            </Col>
                        </Row>
                        {this.state.elementRows.map((element) => { return this.createElementRow(element, true); })}
                        {this.props.fppElements.length > 0 && <>
                            <hr style={{ borderColor: "white" }} />
                            <p>Full Page Personalisation Elements</p>
                            {this.props.fppElements.map((element) => { return this.createElementRow(element, false); })}
                        </>}
                    </Col>
                </Row>
            </>
        );
    }
}