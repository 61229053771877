export const ExperimentsConfig: Array<ExperimentalSlot> = [
    {
        "slotName": "Recommended For You",
        "slotId": "f7cb342f-4dc0-4180-bf47-8e0413a49c8f",
        "type": "RFY",
        "experiments": [
            {
                "id": "rfy_A",
                "model": "rfy_20220607",
                "percentage": 100,
                "notes": "Base Model"
            }
        ]
    },
    {
        "slotName": "More Like This",
        "slotId": "a0182e54-72ee-4180-8a02-8b4289a64a04",
        "type": "MLT",
        "experiments": [
            {
                "id": "mlt_A",
                "model": "mlt_20230120",
                "percentage": 60,
                "notes": "Base Model"
            },
            {
                "id": "mlt_C",
                "model": "mlt_20230225_popularity",
                "percentage": 20,
                "notes": "Test Model B - boosting popular content"
            }
        ]
    }
];

export const RFYModelsList: Array<string> = [
    "rfy_20220912_popularity",
    "rfy_20221123_aws_personalise_base",
    "rfy_20230222_aws_personalise_metadata",
    "rfy_20220607"
];

export const MLTModelsList: Array<string> = [
    "mlt_20230225_recency",
    "mlt_20230225_popularity",
    "mlt_20230120"
];