import svgToDataURL from "svg-to-dataurl";

// All icons taken and adapted from: https://boxicons.com
// Licensed for commerical use under CC 4.0

export function getDefaultPlaceholder(title: string): string {
    // Question-Mark
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9 17 6.243 14.757 4 12 4zM11 18H13V20H11z"/>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

// GENRE PLACEHOLDERS
export function getActionPlaceholder(title: string): string {
    // Car-Crash
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M2.634 17.918c.145.637.619 1.108 1.201 1.291L4.015 20H4v2h16v-2H6.683c.009-.091.015-.184-.007-.278l-.196-.863 10.357-2.356.196.863c.109.477.583.775 1.06.667l.863-.197c.478-.108.775-.583.667-1.06l-.251-1.103c.446-.416.67-1.046.525-1.683l-.59-2.59c-.149-.659-.652-1.14-1.262-1.307l-2.049-3.378c-.62-1.019-1.818-1.524-2.982-1.263L5.146 7.242C3.981 7.507 3.12 8.483 3.002 9.672l-.387 3.932c-.477.415-.721 1.065-.57 1.724L2.634 17.918zM5.654 17.23c-.714.163-1.427-.285-1.589-1-.163-.715.285-1.427 1-1.589s1.427.285 1.59 1C6.817 16.356 6.369 17.068 5.654 17.23zM16.876 14.678c-.715.163-1.427-.286-1.59-1-.162-.715.286-1.427 1.001-1.589s1.427.286 1.589 1C18.039 13.803 17.591 14.515 16.876 14.678zM5.589 9.192l7.869-1.791c.323-.073.657.068.83.351l1.585 2.613-.566.129L5.261 12.781 4.693 12.91l.299-3.042C5.025 9.538 5.265 9.266 5.589 9.192zM18.405 4L17 2 16.5 5 19 9 22 10 20 7.461 22 6.528 20 5.595 22 2z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getComedyPlaceholder(title: string): string {
    // Laugh
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12c0,5.514,4.486,10,10,10s10-4.486,10-10C22,6.486,17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8 s3.589-8,8-8s8,3.589,8,8S16.411,20,12,20z"/>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12 18c4 0 5-4 5-4H7C7 14 8 18 12 18zM17.555 8.832l-1.109-1.664-3 2c-.297.198-.466.539-.443.894.022.356.232.673.551.833l4 2 .895-1.789-2.459-1.229L17.555 8.832zM10.998 10.062c.022-.355-.146-.696-.443-.894l-3-2L6.445 8.832l1.566 1.044-2.459 1.229.895 1.789 4-2C10.766 10.735 10.976 10.418 10.998 10.062z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getCookingPlaceholder(title: string): string {
    // Restaurant
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12 10h-2V3H8v7H6V3H4v8c0 1.654 1.346 3 3 3h1v7h2v-7h1c1.654 0 3-1.346 3-3V3h-2V10zM19 3h-1c-1.159 0-2 1.262-2 3v8h2v7h2V4C20 3.447 19.553 3 19 3z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getCyclingPlaceholder(title: string): string {
    // Cycling
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M11,15.414V20h2v-4.586c0-0.526-0.214-1.042-0.586-1.414l-2-2L13,9.414l2,2C15.372,11.786,15.888,12,16.414,12H20v-2 h-3.586l-3.707-3.707c-0.391-0.391-1.023-0.391-1.414,0L8,9.586C7.622,9.964,7.414,10.466,7.414,11S7.622,12.036,8,12.414 L11,15.414z"></path>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="16" cy="5" r="2"></circle>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M18 14c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4S20.206 14 18 14zM18 20c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2S19.103 20 18 20zM6 22c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4S3.794 22 6 22zM6 16c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2S4.897 16 6 16z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getDocumentaryPlaceholder(title: string): string {
    // Camera-Movie
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M18,11c0-0.959-0.68-1.761-1.581-1.954C16.779,8.445,17,7.75,17,7c0-2.206-1.794-4-4-4c-1.517,0-2.821,0.857-3.5,2.104 C8.821,3.857,7.517,3,6,3C3.794,3,2,4.794,2,7c0,0.902,0.312,1.727,0.817,2.396C2.324,9.761,2,10.342,2,11v8c0,1.103,0.897,2,2,2 h12c1.103,0,2-0.897,2-2v-2.638l4,2v-7l-4,2V11z M13,5c1.103,0,2,0.897,2,2s-0.897,2-2,2s-2-0.897-2-2S11.897,5,13,5z M6,5 c1.103,0,2,0.897,2,2S7.103,9,6,9S4,8.103,4,7S4.897,5,6,5z M4,19v-8h12l0.002,8H4z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getDramaPlaceholder(title: string): string {
    // Combined Smile & Sad
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />

            <path fill="rgb(99,99,99)" transform="translate(78 35) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" />
            <circle fill="rgb(99,99,99)" transform="translate(78 35) scale(4.0)" cx="8.5" cy="10.5" r="1.5" />
            <circle fill="rgb(99,99,99)" transform="translate(78 35) scale(4.0)" cx="15.493" cy="10.493" r="1.493" />
            <path fill="rgb(99,99,99)" transform="translate(78 35) scale(4.0)" d="M12,18c4,0,5-4,5-4H7C7,14,8,18,12,18z" />

            <path fill="rgb(99,99,99)" transform="translate(138 50) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M7,10.5C7,9.672,7.672,9,8.5,9 S10,9.672,10,10.5S9.328,12,8.5,12S7,11.328,7,10.5z M8,17c0,0,1-3,4-3s4,3,4,3H8z M15.493,11.986 c-0.824,0-1.493-0.669-1.493-1.493S14.669,9,15.493,9s1.493,0.669,1.493,1.493S16.317,11.986,15.493,11.986z" />

            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getFantasyPlaceholder(title: string): string {
    // Pyramid
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M11.445,21.832C11.613,21.944,11.807,22,12,22s0.387-0.056,0.555-0.168l9-6c0.229-0.152,0.385-0.393,0.431-0.663 c0.047-0.271-0.021-0.549-0.186-0.769l-9-12c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.165,0.22-0.232,0.497-0.186,0.769 c0.046,0.271,0.202,0.511,0.431,0.663L11.445,21.832z M19.565,14.754L12,19.798V4.667L19.565,14.754z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}


export function getGameshowPlaceholder(title: string): string {
    // Dice-6
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M19,3H5C3.897,3,3,3.897,3,5v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V5C21,3.897,20.103,3,19,3z M5,19V5h14 l0.002,14H5z"></path>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="8" cy="8" r="1.5"></circle>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="8" cy="12" r="1.5" ></circle>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="16" cy="12" r="1.5"></circle>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="16" cy="16" r="1.5"></circle>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="8" cy="16" r="1.5"></circle>
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="16" cy="8" r="1.5"></circle>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getHorrorPlaceholder(title: string): string {
    // Shocked
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12c0,5.514,4.486,10,10,10s10-4.486,10-10C22,6.486,17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8 s3.589-8,8-8s8,3.589,8,8S16.411,20,12,20z" />
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="8.5" cy="10.5" r="1.5" />
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="15.493" cy="10.493" r="1.493" />
            <ellipse fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="12" cy="15.5" rx="3" ry="2.5" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getKidsPlaceholder(title: string): string {
    // Happy
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" />
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="8.5" cy="10.5" r="1.5" />
            <circle fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" cx="15.493" cy="10.493" r="1.493" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,18c4,0,5-4,5-4H7C7,14,8,18,12,18z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getMedicalPlaceholder(title: string): string {
    // First-Aid
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="none" transform="translate(103 40) scale(4.0)" d="M9 4H15V6H9zM13 9L11 9 11 12 8 12 8 14 11 14 11 17 13 17 13 14 16 14 16 12 13 12z"></path>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20,6h-3V4c0-1.103-0.897-2-2-2H9C7.897,2,7,2.897,7,4v2H4C2.896,6,2,6.896,2,8v10c0,1.104,0.896,2,2,2h16 c1.104,0,2-0.896,2-2V8C22,6.896,21.104,6,20,6z M9,4h6v2H9V4z M16,14h-3v3h-2v-3H8v-2h3V9h2v3h3V14z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getMotorsportsPlaceholder(title: string): string {
    // Car
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20.772,10.155l-1.368-4.104C18.995,4.824,17.852,4,16.559,4H7.441C6.148,4,5.005,4.824,4.596,6.051l-1.368,4.104 C2.507,10.457,2,11.169,2,12v5c0,0.738,0.404,1.376,1,1.723v1.174V21c0,0.553,0.447,1,1,1h1c0.553,0,1-0.447,1-1v-2h12v2 c0,0.553,0.447,1,1,1h1c0.553,0,1-0.447,1-1v-2.277c0.596-0.347,1-0.984,1-1.723v-5C22,11.169,21.493,10.457,20.772,10.155z M7.441,6h9.117c0.431,0,0.813,0.274,0.949,0.684L18.613,10h-0.794H6.181H5.387l1.105-3.316C6.629,6.274,7.011,6,7.441,6z M5.5,16 C4.672,16,4,15.328,4,14.5S4.672,13,5.5,13S7,13.672,7,14.5S6.328,16,5.5,16z M18.5,16c-0.828,0-1.5-0.672-1.5-1.5 s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S19.328,16,18.5,16z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getNaturePlaceholder(title: string): string {
    // Tree
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20 18L16 13 19 13 15 8 17 8 12 2 7 8 9 8 5 13 8 13 4 18 11 18 11 22 13 22 13 18z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getNewsPlaceholder(title: string): string {
    // News
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M19.875,3H4.125C2.953,3,2,3.897,2,5v14c0,1.103,0.953,2,2.125,2h15.75C21.047,21,22,20.103,22,19V5 C22,3.897,21.047,3,19.875,3z M19.875,19H4.125c-0.057,0-0.096-0.016-0.113-0.016c-0.007,0-0.011,0.002-0.012,0.008L3.988,5.046 C3.995,5.036,4.04,5,4.125,5h15.75C19.954,5.001,19.997,5.028,20,5.008l0.012,13.946C20.005,18.964,19.96,19,19.875,19z"/>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M6 7H12V13H6zM13 15L6 15 6 17 13 17 14 17 18 17 18 15 14 15zM14 11H18V13H14zM14 7H18V9H14z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>`;

        return svgToDataURL(svg);
}

export function getRadioPlaceholder(title: string): string {

    // Radio
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20.249,5.025l-7.897-2.962l-0.703,1.873L14.484,5H4C2.896,5,2,5.896,2,7v12c0,1.104,0.896,2,2,2h16c1.104,0,2-0.896,2-2V7 C22,5.98,21.234,5.149,20.249,5.025z M10,17H6v-2h4V17z M16.5,18c-1.381,0-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5s2.5,1.119,2.5,2.5 S17.881,18,16.5,18z M20,11H4V7h16V11z"/>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>`;

        return svgToDataURL(svg);
}

export function getRomancePlaceholder(title: string): string {
    // Heart
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20.205,4.791c-1.137-1.131-2.631-1.754-4.209-1.754c-1.483,0-2.892,0.552-3.996,1.558 c-1.104-1.006-2.512-1.558-3.996-1.558c-1.578,0-3.072,0.623-4.213,1.758c-2.353,2.363-2.352,6.059,0.002,8.412L12,21.414 l8.207-8.207C22.561,10.854,22.562,7.158,20.205,4.791z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getRoyaltyPlaceholder(title: string): string {
    // Crown
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M21 2L16 7 12 2 8 7 3 2 3 15 21 15zM5 21h14c1.104 0 2-.896 2-2v-2H3v2C3 20.104 3.896 21 5 21z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getSciencePlaceholder(title: string): string {
    // Flask
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M15,9.783V4h1V2h-2h-4H8v2h1v5.783l-4.268,9.389c-0.283,0.622-0.23,1.337,0.14,1.911C5.241,21.657,5.87,22,6.553,22h10.895 c0.683,0,1.312-0.343,1.681-0.917c0.37-0.574,0.423-1.289,0.14-1.911L15,9.783z M10.91,10.414C10.97,10.284,11,10.143,11,10V4h2v6 c0,0.143,0.03,0.284,0.09,0.414L15.177,15H8.825L10.91,10.414z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getSciFiPlaceholder(title: string): string {
    // Planet
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="none" transform="translate(103 40) scale(4.0)" d="M4.633 17.117c-.374.689-.594 1.516-.377 1.76.23.262.918.281 1.942-.021C5.61 18.354 5.085 17.773 4.633 17.117zM8.349 17.962c1.546-.794 3.426-2.043 5.488-3.876 2.065-1.834 3.527-3.553 4.498-4.994-.105-.227-.221-.449-.351-.667C16.71 6.293 14.474 5.02 12.001 5.02c-1.257 0-2.498.346-3.589.999-1.607.96-2.742 2.482-3.195 4.286-.453 1.803-.173 3.681.789 5.286C6.6 16.586 7.409 17.384 8.349 17.962zM19.474 7.067c.421-.982.483-1.668.252-1.929-.229-.259-.505-.356-.861-.323-.232.025-.577.137-1.008.399C18.469 5.748 19.013 6.366 19.474 7.067zM15.165 15.582c-1.409 1.252-2.926 2.381-4.413 3.292.404.072.815.122 1.237.122 1.257 0 2.499-.346 3.59-.999 1.607-.96 2.741-2.481 3.194-4.285.177-.704.238-1.42.194-2.126C17.887 12.956 16.576 14.329 15.165 15.582z" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M2.76,20.205c0.517,0.581,1.257,0.841,2.147,0.841c0.975,0,2.133-0.319,3.373-0.86c1.144,0.519,2.396,0.811,3.709,0.811 c1.619,0,3.216-0.443,4.616-1.282c2.065-1.234,3.524-3.192,4.107-5.515c0.412-1.643,0.344-3.329-0.163-4.908 c1.31-2.236,1.753-4.263,0.672-5.48c-0.661-0.744-1.58-1.091-2.575-0.983C17.815,2.919,16.96,3.33,16.098,4.02 c-1.243-0.64-2.634-1-4.097-1c-1.618,0-3.215,0.443-4.615,1.282C5.319,5.536,3.861,7.495,3.277,9.817 c-0.449,1.788-0.333,3.63,0.306,5.325C2.518,16.346,1.529,18.819,2.76,20.205z M4.256,18.877c-0.217-0.244,0.003-1.07,0.377-1.76 c0.452,0.656,0.978,1.237,1.565,1.739C5.174,19.158,4.486,19.139,4.256,18.877z M18.773,13.712 c-0.453,1.804-1.587,3.325-3.194,4.285c-1.091,0.653-2.333,0.999-3.59,0.999c-0.422,0-0.833-0.05-1.237-0.122 c1.487-0.911,3.004-2.04,4.413-3.292c1.411-1.253,2.722-2.626,3.803-3.996C19.012,12.292,18.95,13.008,18.773,13.712z M18.864,4.815c0.356-0.033,0.632,0.064,0.861,0.323c0.231,0.261,0.169,0.946-0.252,1.929c-0.461-0.701-1.005-1.319-1.617-1.853 C18.287,4.952,18.632,4.841,18.864,4.815z M5.217,10.305C5.67,8.501,6.805,6.979,8.412,6.019c1.091-0.653,2.332-0.999,3.589-0.999 c2.473,0,4.709,1.273,5.983,3.405c0.13,0.218,0.245,0.44,0.351,0.667c-0.971,1.441-2.433,3.16-4.498,4.994 c-2.063,1.833-3.942,3.082-5.488,3.876c-0.939-0.578-1.749-1.376-2.343-2.371C5.044,13.985,4.764,12.107,5.217,10.305z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getShoppingPlaceholder(title: string): string {
    // Shopping-Bags
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M4,20h2V10c0-0.553,0.447-1,1-1h12V7c0-0.553-0.447-1-1-1h-3.051c-0.252-2.244-2.139-4-4.449-4S6.303,3.756,6.051,6H3 C2.447,6,2,6.447,2,7v11C2,19.104,2.896,20,4,20z M10.5,4c1.207,0,2.218,0.86,2.45,2h-4.9C8.282,4.86,9.293,4,10.5,4z"></path>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M21,11H9c-0.553,0-1,0.447-1,1v8c0,1.104,0.896,2,2,2h10c1.104,0,2-0.896,2-2v-8C22,11.447,21.553,11,21,11z M15,18 c-2.757,0-5-2.243-5-5h2c0,1.654,1.346,3,3,3s3-1.346,3-3h2C20,15.757,17.757,18,15,18z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getSportsPlaceholder(title: string): string {
    // Football
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M19.071,4.929c-1.895-1.895-4.405-2.938-7.07-2.938c-2.666,0-5.177,1.044-7.072,2.938c-3.899,3.898-3.899,10.243,0,14.142 c1.895,1.895,4.406,2.938,7.073,2.938c2.666,0,5.176-1.043,7.07-2.937C22.971,15.174,22.971,8.829,19.071,4.929z M12.181,4h-0.359 c0.061-0.001,0.119-0.009,0.18-0.009S12.12,3.999,12.181,4z M18.243,17H16l-1.258,2.516c-0.868,0.318-1.791,0.493-2.741,0.493 c-0.952,0-1.876-0.175-2.746-0.494L8,17.01H5.765c-0.846-1.052-1.387-2.269-1.623-3.532L6,11L4.784,8.567 c0.381-0.804,0.895-1.56,1.559-2.224c0.919-0.92,2.021-1.584,3.22-1.969L12,6l2.438-1.625c1.198,0.385,2.301,1.049,3.22,1.968 c0.663,0.663,1.177,1.418,1.558,2.221L18,11l1.858,2.478C19.623,14.737,19.085,15.95,18.243,17z" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M8.5 11L10 15 14 15 15.5 11 12 8.5z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getStandUpPlaceholder(title: string): string {
    // Microphone-alt
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M6,12H4c0,4.072,3.061,7.436,7,7.931V22h2v-2.069c3.939-0.495,7-3.858,7-7.931h-2c0,3.309-2.691,6-6,6S6,15.309,6,12z" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M8,12c0,2.206,1.794,4,4,4s4-1.794,4-4h-2v-2h2V8h-2V6h2c0-2.217-1.785-4.021-3.979-4.021c-0.069,0-0.14,0.009-0.209,0.025 C9.693,2.104,8,3.857,8,6h4v2H8v2h4v2H8z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getThrillerPlaceholder(title: string): string {
    // Skull
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C5.505,2,2,6.637,2,11c0,2.129,1.009,3.979,3,5.508V21h3v-3h2v3h4v-3h2v3h3v-4.493c1.991-1.528,3-3.379,3-5.507 C22,6.637,18.495,2,12,2z M8,13c-1.121,0-2-1.098-2-2.5S6.879,8,8,8s2,1.098,2,2.5S9.121,13,8,13z M16,13c-1.121,0-2-1.098-2-2.5 S14.879,8,16,8s2,1.098,2,2.5S17.121,13,16,13z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getTravelPlaceholder(title: string): string {
    // World
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M4,12c0-0.899,0.156-1.762,0.431-2.569L6,11l2,2 v2l2,2l1,1v1.931C7.061,19.436,4,16.072,4,12z M18.33,16.873C17.677,16.347,16.687,16,16,16v-1c0-1.104-0.896-2-2-2h-4v-2v-1 c1.104,0,2-0.896,2-2V7h1c1.104,0,2-0.896,2-2V4.589C17.928,5.778,20,8.65,20,12C20,13.835,19.373,15.522,18.33,16.873z"></path>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getVaultPlaceholder(title: string): string {
    // Lock
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M12,2C9.243,2,7,4.243,7,7v3H6c-1.104,0-2,0.896-2,2v8c0,1.104,0.896,2,2,2h2h3h2h2h3c1.104,0,2-0.896,2-2v-8 c0-1.104-0.896-2-2-2h-1V7C17,4.243,14.757,2,12,2z M9,7c0-1.654,1.346-3,3-3s3,1.346,3,3v3H9V7z M13,17.723V20h-2v-2.277 c-0.727-0.422-1.169-1.278-0.939-2.224c0.176-0.72,0.78-1.302,1.506-1.453C12.861,13.774,14,14.753,14,16 C14,16.739,13.596,17.377,13,17.723z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getWeatherPlaceholder(title: string): string {
    // Cloud-Drizzle
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M8 13H10V16H8zM8 17H10V20H8zM11 15H13V18H11zM11 19H13V22H11zM14 13H16V16H14zM14 17H16V20H14z"/>
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M18.944,10.112C18.507,6.67,15.56,4,12,4C9.245,4,6.85,5.611,5.757,8.15C3.609,8.792,2,10.819,2,13c0,2.757,2.243,5,5,5v-2 c-1.654,0-3-1.346-3-3c0-1.403,1.199-2.756,2.673-3.015l0.582-0.103l0.191-0.559C8.149,7.273,9.895,6,12,6c2.757,0,5,2.243,5,5v1h1 c1.103,0,2,0.897,2,2s-0.897,2-2,2h-1v2h1c2.206,0,4-1.794,4-4C22,12.119,20.695,10.538,18.944,10.112z"/>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>`;

        return svgToDataURL(svg);
}

// TYPE PLACEHOLDERS
export function getMusicPlaceholder(title: string): string {
    // Music
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M6,18.573c2.206,0,4-1.794,4-4V4.428L19,7.7v7.43c-0.591-0.345-1.269-0.557-2-0.557c-2.206,0-4,1.794-4,4s1.794,4,4,4 s4-1.794,4-4V7c0-0.421-0.263-0.796-0.658-0.939l-11-4c-0.308-0.113-0.648-0.067-0.915,0.12C8.159,2.368,8,2.674,8,3v8.13 c-0.591-0.345-1.269-0.557-2-0.557c-2.206,0-4,1.794-4,4S3.794,18.573,6,18.573z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getMoviePlaceholder(title: string): string {
    // Movie
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20,3H4C2.897,3,2,3.897,2,5v6v8c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-8V5C22,3.897,21.103,3,20,3z M20.001,9 C20,9,20,9,20.001,9h-0.466l-2.667-4H20L20.001,9z M14.535,9l-2.667-4h2.596l2.667,4H14.535z M12.131,9H9.535L6.869,5h2.596 L12.131,9z M4,5h0.465l2.667,4H4V5z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getTVPlaceholder(title: string): string {
    // TV
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(103 40) scale(4.0)" d="M20,6h-5.586l2.293-2.293l-1.414-1.414L12,5.586L8.707,2.293L7.293,3.707L9.586,6H4C2.897,6,2,6.897,2,8v11 c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V8C22,6.897,21.103,6,20,6z M4,19V8h16l0.002,11H4z" />
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getCompilationPlaceholder(title: string): string {
    // Collection
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(100 30) scale(6.0)" d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z"/>
            <text x="50%" y="55%" text-anchor="middle">${title}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getUserPlaceholder(username: string): string {
    // User
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(75 10) scale(6.0)" d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h1 1 14H20z"/>

            <text x="50%" y="42%" text-anchor="middle" font-size="40px">User:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${username}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterMoviePlaceholder(cluster: string): string {
    // Movie
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(60 -10) scale(7.5)" d="M20,3H4C2.897,3,2,3.897,2,5v6v8c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-8V5C22,3.897,21.103,3,20,3z M20.001,9 C20,9,20,9,20.001,9h-0.466l-2.667-4H20L20.001,9z M14.535,9l-2.667-4h2.596l2.667,4H14.535z M12.131,9H9.535L6.869,5h2.596 L12.131,9z M4,5h0.465l2.667,4H4V5z" />
            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterTVSeriesPlaceholder(cluster: string): string {
    // TV
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(60 -10) scale(7.5)" d="M20,6h-5.586l2.293-2.293l-1.414-1.414L12,5.586L8.707,2.293L7.293,3.707L9.586,6H4C2.897,6,2,6.897,2,8v11 c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V8C22,6.897,21.103,6,20,6z M4,19V8h16l0.002,11H4z" />
            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterFamilyPlaceholder(cluster: string): string {
    // People combine
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />

            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m162.56 351.268 7.52-8.45c-5.494-17.559-5.509-36.89.36-54.74-43.22 23.745-97.937 14.283-130.6-24.35-24.26 19.24-39.84 48.97-39.84 82.27v150c0 8.28 6.72 15 15 15h123.58c-1.67-4.69-2.58-9.74-2.58-15v-75c0-25.72 9.43-50.49 26.56-69.73z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="120" cy="195.998" r="75"/>
            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m472.16 263.728c-32.722 38.703-87.477 48.061-130.6 24.34 5.86 17.886 5.861 37.167.36 54.75l7.52 8.45c17.13 19.24 26.56 44.01 26.56 69.73v75c0 5.26-.91 10.31-2.58 15h123.58c8.28 0 15-6.72 15-15v-150c0-33.3-15.58-63.03-39.84-82.27z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="392" cy="195.998" r="75"/>
            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m256 405.998c-28.84 0-54.55-13.63-71.03-34.79-11.8 13.26-18.97 30.7-18.97 49.79v75c0 8.28 6.72 15 15 15h150c8.28 0 15-6.72 15-15v-75c0-19.09-7.17-36.53-18.97-49.79-16.48 21.16-42.19 34.79-71.03 34.79z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="256" cy="315.998" r="60"/>

            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterMixedPlaceholder(cluster: string): string {
    // People combine
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />

            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m162.56 351.268 7.52-8.45c-5.494-17.559-5.509-36.89.36-54.74-43.22 23.745-97.937 14.283-130.6-24.35-24.26 19.24-39.84 48.97-39.84 82.27v150c0 8.28 6.72 15 15 15h123.58c-1.67-4.69-2.58-9.74-2.58-15v-75c0-25.72 9.43-50.49 26.56-69.73z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="120" cy="195.998" r="75"/>
            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m472.16 263.728c-32.722 38.703-87.477 48.061-130.6 24.34 5.86 17.886 5.861 37.167.36 54.75l7.52 8.45c17.13 19.24 26.56 44.01 26.56 69.73v75c0 5.26-.91 10.31-2.58 15h123.58c8.28 0 15-6.72 15-15v-150c0-33.3-15.58-63.03-39.84-82.27z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="392" cy="195.998" r="75"/>
            <path fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" d="m256 405.998c-28.84 0-54.55-13.63-71.03-34.79-11.8 13.26-18.97 30.7-18.97 49.79v75c0 8.28 6.72 15 15 15h150c8.28 0 15-6.72 15-15v-75c0-19.09-7.17-36.53-18.97-49.79-16.48 21.16-42.19 34.79-71.03 34.79z"/>
            <circle  fill="rgb(99,99,99)" transform="translate(70 -25) scale(0.32)" cx="256" cy="315.998" r="60"/>

            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterDramaPlaceholder(cluster: string): string {
    // Combined Smile & Sad
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />

            <path fill="rgb(99,99,99)" transform="translate(38 -10) scale(6.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" />
            <circle fill="rgb(99,99,99)" transform="translate(38 -10) scale(6.0)" cx="8.5" cy="10.5" r="1.5" />
            <circle fill="rgb(99,99,99)" transform="translate(38 -10) scale(6.0)" cx="15.493" cy="10.493" r="1.493" />
            <path fill="rgb(99,99,99)" transform="translate(38 -10) scale(6.0)" d="M12,18c4,0,5-4,5-4H7C7,14,8,18,12,18z" />

            <path fill="rgb(99,99,99)" transform="translate(125 40) scale(6.0)" d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M7,10.5C7,9.672,7.672,9,8.5,9 S10,9.672,10,10.5S9.328,12,8.5,12S7,11.328,7,10.5z M8,17c0,0,1-3,4-3s4,3,4,3H8z M15.493,11.986 c-0.824,0-1.493-0.669-1.493-1.493S14.669,9,15.493,9s1.493,0.669,1.493,1.493S16.317,11.986,15.493,11.986z" />

            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterActionPlaceholder(cluster: string): string {
    // Car-Crash
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(60 -10) scale(7.5)" d="M2.634 17.918c.145.637.619 1.108 1.201 1.291L4.015 20H4v2h16v-2H6.683c.009-.091.015-.184-.007-.278l-.196-.863 10.357-2.356.196.863c.109.477.583.775 1.06.667l.863-.197c.478-.108.775-.583.667-1.06l-.251-1.103c.446-.416.67-1.046.525-1.683l-.59-2.59c-.149-.659-.652-1.14-1.262-1.307l-2.049-3.378c-.62-1.019-1.818-1.524-2.982-1.263L5.146 7.242C3.981 7.507 3.12 8.483 3.002 9.672l-.387 3.932c-.477.415-.721 1.065-.57 1.724L2.634 17.918zM5.654 17.23c-.714.163-1.427-.285-1.589-1-.163-.715.285-1.427 1-1.589s1.427.285 1.59 1C6.817 16.356 6.369 17.068 5.654 17.23zM16.876 14.678c-.715.163-1.427-.286-1.59-1-.162-.715.286-1.427 1.001-1.589s1.427.286 1.589 1C18.039 13.803 17.591 14.515 16.876 14.678zM5.589 9.192l7.869-1.791c.323-.073.657.068.83.351l1.585 2.613-.566.129L5.261 12.781 4.693 12.91l.299-3.042C5.025 9.538 5.265 9.266 5.589 9.192zM18.405 4L17 2 16.5 5 19 9 22 10 20 7.461 22 6.528 20 5.595 22 2z" />
            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}

export function getClusterDocumentaryPlaceholder(cluster: string): string {
    // Camera-Movie
    const svg = `
        <svg viewBox="0 0 300 170" xmlns="http://www.w3.org/2000/svg" style="fill:white;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;font-weight: 500">
            <rect width="300" height="170" style="fill:rgb(55,55,55);" />
            <path fill="rgb(99,99,99)" transform="translate(60 -10) scale(7.5)" d="M18,11c0-0.959-0.68-1.761-1.581-1.954C16.779,8.445,17,7.75,17,7c0-2.206-1.794-4-4-4c-1.517,0-2.821,0.857-3.5,2.104 C8.821,3.857,7.517,3,6,3C3.794,3,2,4.794,2,7c0,0.902,0.312,1.727,0.817,2.396C2.324,9.761,2,10.342,2,11v8c0,1.103,0.897,2,2,2 h12c1.103,0,2-0.897,2-2v-2.638l4,2v-7l-4,2V11z M13,5c1.103,0,2,0.897,2,2s-0.897,2-2,2s-2-0.897-2-2S11.897,5,13,5z M6,5 c1.103,0,2,0.897,2,2S7.103,9,6,9S4,8.103,4,7S4.897,5,6,5z M4,19v-8h12l0.002,8H4z" />
            <text x="50%" y="42%" text-anchor="middle" font-size="40px">Cluster:</text>
            <text x="50%" y="68%" text-anchor="middle" font-size="40px">${cluster}</text>
        </svg>
    `;

    return svgToDataURL(svg);
}