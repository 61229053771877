import React, { ReactNode } from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { SearchResults } from "../components/SearchResults";
import { getCollectionsURL, makeSlotRequest } from "../utils/Requests";
import Logger from "../utils/Logger";
import * as utils from "../utils/Utils";

const StickyColumn = styled(Col)`
    position: fixed;
    top: calc(60px + 1rem);
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: scroll;

    /* Hide the scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const FilterContainer = styled.div`
    border-radius: 3px;
    padding: 10px;
    margin-left: 10px;
`;

const FilterLabel = styled.h5`
    text-align:center;
`;

const ContentColumn = styled(Col)`
    margin-left: 25%;
    margin-right: 10%;
`;

const CollectionListContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: block;
  }
`;

const CollectionListItem = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  color: grey;
  cursor: pointer;
  font-size: 16px;
  text-align: left;

  &.selected {
    color: white;
  }
`;

type CollectionsViewerState = {
    isLoading: boolean,
    isLoaded: boolean,
    error: Error | null,
    items: Array<Record<string, any>>,
    collections: Array<Record<string, any>>,
    collectionId: string | null
}

type CollectionsViewerProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    metadataAccess?: boolean,
    customerConfig: CustomerConfig
}

export default class CollectionsViewer extends React.Component<CollectionsViewerProperties, CollectionsViewerState> {
    constructor(props: CollectionsViewerProperties) {
        super(props);

        this.state = {
            collections: [],
            collectionId: null,
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false
        };
    }


    public componentDidMount(): void {
        if (this.props.customerConfig) {
            this.getCollections();
        }
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            isLoading: false,
            isLoaded: true,
            error
        });
    }

    private async getCollections(): Promise<void> {
        await makeSlotRequest(getCollectionsURL(this.props.customerConfig)).then((response) => {
            if (response.status !== 200) {
                if (response.status === 404) {
                    throw new Error("404: Not Found");
                } else {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        response.json().then((error) => {
                            this.handleError(error);
                        });
                    } else {
                        response.text().then((error) => {
                            this.handleError(new Error(error));
                        });
                    }
                }
            } else {
                response.json().then(async (result) => {
                    if (result) {
                        const collections = result.items;

                        this.setState({
                            isLoading: false,
                            isLoaded: true,
                            collections: collections || [],
                            error: null
                        });
                    }
                });
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    private getUrl = () => {
        let url = getCollectionsURL(this.props.customerConfig);

        if (this.state.collectionId) {
            url += `?_debug_metadata=true&ignore=testtool&seedIds=${encodeURIComponent(this.state.collectionId.trim())}`;
            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);
        }

        return encodeURI(url);
    }

    public async requestItems(): Promise<void> {
        await makeSlotRequest(this.getUrl())
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                isLoading: false,
                                isLoaded: true,
                                items: items || [],
                                error: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        }, this.requestItems);
    };

    public handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const value = event.currentTarget.value;
        this.setState({
            collectionId: value
        }, this.update);
    }

    private renderCollectionResults(): ReactNode {
        const { error, isLoading, isLoaded, collectionId } = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (isLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if ((!isLoading && !isLoaded) || collectionId === null) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <SearchResults
                customer={this.props.customerConfig}
                showMetadataLink={this.props.metadataAccess}
                items={this.state.items}
                username={this.props.username}
                groups={this.props.groups}
            />;
        }
    }

    public render(): ReactNode {
        const { collections, collectionId  } = this.state;

        if (this.props.customerConfig.collectionsSlot === undefined ||
            this.props.customerConfig.collectionsSlot === "") {
            return (
                <Container className="mw-100" style={{"marginTop": "10px"}}>
                    <Row className="justify-content-center">
                        Collections not available for this customer, contact the 24iQ team to get them set-up
                    </Row>
                </Container>
            );
        }

        return (
            <div style={{ overflowX: "hidden" }}>
                <Container className="mw-100" style={{"marginTop": "10px"}}>
                    <Row>
                        <StickyColumn md={3}>
                            <FilterContainer style={{ background: "#282828" }}>
                                <Row>
                                    <Col>
                                        <FilterLabel>Collections</FilterLabel>
                                        <CollectionListContainer>
                                            {collections?.map((collection, i) => (
                                                <CollectionListItem
                                                    key={i}
                                                    value={collection["id"]}
                                                    onClick={this.handleOnClick}
                                                    className={collectionId === (collection["id"]) ? "selected" : ""}
                                                >
                                                    {collection["title"]}
                                                </CollectionListItem>
                                            ))}
                                        </CollectionListContainer>
                                    </Col>
                                </Row>
                            </FilterContainer>
                        </StickyColumn>
                        <ContentColumn md={9} className="mr-3">
                            <Row className="justify-content-center">
                                <Col>{this.renderCollectionResults()}</Col>
                            </Row>
                        </ContentColumn>
                    </Row>
                </Container>
            </div>
        );
    }
}