import React, { ReactNode } from "react";
import {
    Button, Col, Container, InputGroup,
    OverlayTrigger, Row, Tooltip, Form, Alert
} from "react-bootstrap";
import styled from "styled-components";
import "rc-slider/assets/index.css";

import { ExperimentsConfig, MLTModelsList, RFYModelsList } from "../config/SlotExperiments";
import { ExperimentRow } from "../components/experiments/ExperimentRow";
import { generateGuid } from "../utils/Utils";

const AddPromotionsCol = styled(Col)`
    background: #282828;
    margin-top: 10px;
    border-radius: 3px;
    padding: 5px;
`;

const FieldIcon = styled(InputGroup.Text)`
    background: black;
    padding-left: 10px;
    padding-right: 10px;
`;

const Spacer = styled.div`
    margin: 0 5px;
`;

const ExperimentAlert = styled(Alert)`
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: 10;
    min-width: 200px;
    height: 42px;
`;

type ExperimentsEditorState = {
    selectedSlot: string,
    slot: ExperimentalSlot,
    experiments: Array<Experiment>,
    modelList: Array<string>,
    showSuccess: boolean,
    showError: boolean,
    errorMsg: string
}

type ExperimentsEditorProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

class ExperimentsEditor extends React.Component<ExperimentsEditorProperties, ExperimentsEditorState> {

    constructor(props: ExperimentsEditorProperties) {
        super(props);

        let modelList: Array<string> = [];

        if (ExperimentsConfig[0].type === "RFY") modelList = RFYModelsList;
        if (ExperimentsConfig[0].type === "MLT") modelList = MLTModelsList;

        this.state = {
            slot: ExperimentsConfig[0],
            selectedSlot: ExperimentsConfig[0].slotId,
            experiments: ExperimentsConfig[0].experiments,
            modelList,
            showSuccess: false,
            showError: false,
            errorMsg: ""
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.setState({
            experiments: ExperimentsConfig[0].experiments
        });
    }

    private selectSlot = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const experimentalSlot = this.getSlotExperiments(event.target.value);

        let modelList: Array<string> = [];

        if (experimentalSlot.type === "RFY") modelList = RFYModelsList;
        if (experimentalSlot.type === "MLT") modelList = MLTModelsList;

        this.setState({
            selectedSlot: event.target.value,
            slot: experimentalSlot,
            experiments: experimentalSlot.experiments,
            modelList
        });
    }

    private getSlotExperiments(slotId: string): ExperimentalSlot {
        return ExperimentsConfig.filter((slot) => { return slot.slotId === slotId; })[0];
    }

    private renderExperiments(): ReactNode {
        return (
            <>
                {this.state.experiments?.map((experiment, i) => {
                    return <ExperimentRow
                        key={`experiment-${String.fromCharCode("A".charCodeAt(0) + i)}`}
                        id={experiment.id}
                        position={String.fromCharCode("A".charCodeAt(0) + i)}
                        models={this.state.modelList}
                        selectedModel={experiment.model}
                        notes={experiment.notes}
                        percentage={experiment.percentage}
                        deleteCallback={this.removeRow}
                        updateModel={this.updateModel}
                        updateNotes={this.updateNotes}
                        updatePercentage={this.updatePercentage}
                        removable={this.state.experiments.length !== 1}
                    />;
                })}
            </>
        );
    }

    private addExperimentRow = (): void => {
        const experimentKeys = this.state.experiments.map(experiment => experiment.model);

        const availableModels: Array<string> = [];

        this.state.modelList.forEach((model) => {
            if (!experimentKeys.includes(model)) {
                availableModels.push(model);
            }
        });

        const newExperiment: Experiment = {
            id: generateGuid(),
            model: availableModels[0],
            notes: `Model ${String.fromCharCode("A".charCodeAt(0) + this.state.experiments.length)} - ${availableModels[0]}`,
            percentage: 10
        };

        const experiments = this.state.experiments;
        experiments[0].percentage = experiments[0].percentage - 10;
        experiments.push(newExperiment);

        this.setState({
            experiments
        });
    }

    private removeRow = (id: string): void => {
        const filteredExperiments = this.state.experiments.filter((experiment) => { return experiment.id !== id; });
        const removeExperiment = this.state.experiments.filter((experiment) => { return experiment.id === id; })[0];

        filteredExperiments[0].percentage = filteredExperiments[0].percentage + removeExperiment.percentage;

        this.setState({
            experiments: filteredExperiments
        });
    }

    private updateNotes = (id: string, value: string): void => {
        const experiments = this.state.experiments;

        experiments.forEach((experiment: Experiment) => {
            if (experiment.id === id) experiment["notes"] = value;
        });

        this.setState({
            experiments
        });
    }

    private updatePercentage = (id: string, value: number): void => {
        const experiments = this.state.experiments;

        experiments.forEach((experiment: Experiment) => {
            if (experiment.id === id) experiment["percentage"] = value;
        });

        this.setState({
            experiments
        });
    }

    private updateModel = (id: string, value: string): void => {
        const experiments = this.state.experiments;

        experiments.forEach((experiment: Experiment) => {
            if (experiment.id === id) experiment["model"] = value;
        });

        this.setState({
            experiments
        });
    }

    private saveExperiment = (): void => {

        let percentTotal = 0;
        this.state.experiments.forEach((exp) => {
            percentTotal += exp.percentage;
        });


        if (percentTotal !== 100) {
            setTimeout(() => {
                this.setState({
                    showError: true,
                    errorMsg: "Experiment percentage must be 100"
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showError: false,
                    errorMsg: ""
                });
            }, 2500);
        } else {
            setTimeout(() => {
                this.setState({
                    showSuccess: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showSuccess: false
                });
            }, 2500);
        }
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    public render(): ReactNode {

        return (
            <>
                <ExperimentAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Experiment configuration saved
                </ExperimentAlert>
                <ExperimentAlert
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error: {this.state.errorMsg}
                </ExperimentAlert>
                <Container className="mw-100" key="promotions">
                    <Row className="justify-content-center">
                        <AddPromotionsCol md={6} className="d-flex justify-content-start">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <OverlayTrigger
                                        overlay={<Tooltip id="model-tooltip">Model</Tooltip>}
                                    >
                                        <FieldIcon>Slot</FieldIcon>
                                    </OverlayTrigger>
                                </InputGroup.Prepend>
                                <Col md={6}>
                                    <Form.Control
                                        as="select"
                                        id={"modelSelector"}
                                        title="Select Model Type"
                                        value={this.state.selectedSlot}
                                        style={{ padding: "0.375rem" }}
                                        onChange={this.selectSlot}>
                                        {ExperimentsConfig.map((slot: ExperimentalSlot, i: number) => {
                                            return (
                                                <option value={slot.slotId} key={slot.slotId}>
                                                    {slot.slotName} ({slot.slotId})
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Col>
                            </InputGroup>
                        </AddPromotionsCol>
                        <AddPromotionsCol md={4} className="d-flex justify-content-end">
                            <Button
                                variant="secondary"
                                disabled={this.state.experiments.length === this.state.modelList.length}
                                onClick={this.addExperimentRow}
                            >
                                Add Experiment
                            </Button>
                            <Spacer />
                            <Button
                                onClick={this.saveExperiment}
                            >
                                Save
                            </Button>
                        </AddPromotionsCol>
                    </Row>
                    <Row className="justify-content-center" style={{ marginTop: "20px" }}>
                        <Col md={10}>
                            {this.renderExperiments()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ExperimentsEditor;