import React, { ReactNode, RefObject } from "react";
import { ResultGrid } from "../ResultGrid";
import { getHostURL } from "../../utils/Requests";

type BrowseViewState = {
    customername: string,
    hostname: string,
    searchResults: Array<SearchItem>,
    userId: string,
    searchTerm: string,
    searchParameters: string,
    additionalParameters?: Record<string, string>,
    items: Array<Record<string, any>>,
}

type BrowseViewProperties = {
    customer: CustomerConfig,
    username: string,
    groups: string[],
    metadataAccess?: boolean,
    addParams?: Record<string, string>,
    aToZ?: boolean,
    zToA?: boolean,
}

export default class BrowseView extends React.Component<BrowseViewProperties, BrowseViewState> {

    private resultGridElement: RefObject<ResultGrid>;

    constructor(props: BrowseViewProperties) {
        super(props);

        this.resultGridElement = React.createRef<ResultGrid>();

        this.state = {
            customername: this.props.customer.name,
            hostname: "",
            searchResults: [],
            userId: "",
            searchTerm: "",
            searchParameters: "",
            items: []
        };
    }

    private getAddParams = (addParams: Record<string, string> | undefined): string => {
        if (addParams) {
            return "&" + this.toQueryString(addParams);
        }

        return "";
    }

    private toQueryString(params: Record<string, string>): string {
        const query = Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join("&");

        return decodeURIComponent(query);
    }


    public render(): ReactNode {
        const { customer, addParams, username, groups, metadataAccess, aToZ, zToA } = this.props;

        const requestType = "GET";

        return (
            <>
                <ResultGrid
                    key={JSON.stringify({
                        addParams: addParams,
                        customer: customer,
                        aToZ: aToZ,
                        zToA: zToA
                    })}
                    ref={this.resultGridElement}
                    hostName={getHostURL(customer)}
                    customer={this.props.customer}
                    slotId={customer.browseSlot}
                    requestOnLoad={true}
                    parameters={this.getAddParams(addParams)}
                    showMetadataLink={metadataAccess}
                    requestType={requestType}
                    username={username}
                    groups={groups}
                    fromBrowse={true}
                    aToZ={aToZ}
                    zToA={zToA}
                />
            </>
        );

    }
}
