import React, { ReactNode } from "react";
import { Alert, Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { X as CrossIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import * as constants from "../../constants";

const CentralCol = styled(Col)`
    text-align: center;
`;

const PageLink = styled(Button)`
    padding: 0;
`;

const DeleteButton = styled(Button)`
    padding: 0px 5px;
`;

type OverviewState = {
    showModal: boolean,
    deletePageId: string,
    deletePageName: string,
    showSuccess: boolean,
    showError: boolean
}

type OverviewProperties = {
    pages: Record<string,any>[],
    groups: string[],
    deleteCallback: (pageId: string) => void,
    selectCallback: (pageId: string) => void
}

const AssocAlert = styled(Alert)`
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

export default class Overview extends React.Component<OverviewProperties, OverviewState> {

    constructor(props: OverviewProperties) {
        super(props);

        this.state = {
            showModal: false,
            deletePageId: "",
            deletePageName: "",
            showSuccess: false,
            showError: false
        };
    }

    private renderComposerPageList(): ReactNode {
        return <Row>
            <Table striped bordered responsive size="sm">
                <thead>
                    <tr>
                        <th>Page Name</th>
                        <th>User ID</th>
                        <th>Elements</th>
                        <th>Date Created</th>
                        <th>Created By</th>
                        <th>Date Updated</th>
                        <th>Updated By</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {this.props.pages.map(page => (
                        <tr key={page.id}>
                            <td key={page.id + "name"}>
                                <PageLink variant="link" onClick={() => {this.props.selectCallback(page["pageId"]);}}>
                                    {page.pageName}
                                </PageLink>
                            </td>
                            <td key={page.id + "userId"}>
                                <span>{page.pageUsrId && page.pageUserId.substring(0, 20)}&hellip;</span>
                            </td>
                            <td key={page.id + "elementCount"}>
                                {JSON.parse(page["pageDefinition"]).length}
                            </td>
                            <td key={page.id + "dateCreated"}>
                                {page.dateCreated.replace("T", " ")}
                            </td>
                            <td key={page.id + "createdBy"}>
                                {page.createdBy}
                            </td>
                            <td key={page.id + "dateUpdated"}>
                                {page.dateUpdated.replace("T", " ")}
                            </td>
                            <td key={page.id + "lastUpdatedBy"}>
                                {page.lastUpdatedBy}
                            </td>
                            {this.props.groups.includes(constants.THE_FILTER_FULL_AUTH) &&
                            <td>
                                <DeleteButton variant="danger" onClick={() => {this.confirmDeletePage(page["pageId"], page["pageName"]);}}>
                                    <CrossIcon size="22" />
                                </DeleteButton>
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>;

    }

    private reset() {
        this.setState({
            deletePageId: "",
            deletePageName: ""
        });
    }

    private handleDeleteModalShow() {
        this.setState({
            showModal: true
        });
    }

    private handleDeleteModalClose() {
        this.setState({
            showModal: false
        }, () => {
            setTimeout(() => { this.reset(); }, 200);
        });
    }

    private confirmDeletePage(pageId: string, pageName: string) {
        this.setState({
            deletePageId: pageId,
            deletePageName: pageName
        }, this.handleDeleteModalShow);
    }

    private deleteCallback() {
        try {
            this.props.deleteCallback(this.state.deletePageId);

            setTimeout(() => {
                this.setState({
                    showSuccess: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showSuccess: false
                });
            }, 4000);
        } catch {
            setTimeout(() => {
                this.setState({
                    showError: true
                });
            }, 500);
            setTimeout(() => {
                this.setState({
                    showError: false
                });
            }, 4000);
        }
        this.handleDeleteModalClose();
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private closeError = (): void => {
        this.setState({
            showError: false
        });
    }

    public render(): ReactNode {
        return (
            <>

                <Modal
                    show={this.state.showModal}
                    onHide={()=> { this.handleDeleteModalClose(); }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Composer Page?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Do you want to delete the composer page: <b>{this.state.deletePageName}</b>?
                            <br/>
                            <b>This cannot be undone</b>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.handleDeleteModalClose();}}>Cancel</Button>
                        <Button variant="danger" onClick={() => {this.deleteCallback();}}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                <AssocAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Page deleted
                </AssocAlert>
                <AssocAlert
                    variant='danger'
                    show={this.state.showError}
                    onClose={this.closeError}
                    dismissible
                >
                    Error deleting page
                </AssocAlert>

                <Container className="mw-100">
                    <Row className="justify-content-center" style={{overflowY: "scroll", height: "80vh"}}>
                        <CentralCol md={11}>
                            {this.renderComposerPageList()}
                        </CentralCol>
                    </Row>
                </Container>
            </>
        );
    }
}