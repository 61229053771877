import Slider from "rc-slider";
import React, { ReactNode } from "react";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Branding from "../config/Branding";

const FieldIcon = styled(InputGroup.Text)`
    background: black;
    padding-left: 10px;
    padding-right: 10px;
`;

const StyledSlider = styled(Slider)`
    right: 5px;
`;

const Label = styled(Col)`
    text-align: left;
`;

const handleStyle = {
    border: `solid 2px ${Branding.primary}`
};

const dotStyle = {
    borderColor: `${Branding.primary}`
};

const trackStyle = {
    backgroundColor: `${Branding.primary}`
};


type ParameterSliderProperties = {
    name: string,
    initialValue: number
}

type ParameterSliderState = {
    value: number
}

export class ParameterSlider extends React.Component<ParameterSliderProperties, ParameterSliderState> {

    constructor(props: ParameterSliderProperties) {
        super(props);

        this.state = {
            value: this.props.initialValue
        };
    }

    private onSliderChange = (value: number): void => {
        this.setState({
            value
        });
    }

    public render(): ReactNode {
        return (
                <Row className="justify-content-center" style={{marginTop: "10px"}}>
                    <Col md={6}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <OverlayTrigger
                                    overlay={<Tooltip id="customer-tooltip">Parameter</Tooltip>}
                                >
                                    <FieldIcon>Parameter:</FieldIcon>
                                </OverlayTrigger>
                            </InputGroup.Prepend>
                            <Form.Control
                                style={{backgroundColor: "white"}}
                                id="parameterInput"
                                type="string"
                                placeholder="parameter"
                                defaultValue={this.props.name}
                                readOnly
                            />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Label xs="2" xsm="2">
                                <FieldIcon>Value:</FieldIcon>
                            </Label>
                            <Col xs="7" sm="7">
                                <StyledSlider
                                    min={0}
                                    max={5}
                                    defaultValue={this.state.value}
                                    marks={{
                                        0: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "0"
                                        },
                                        1: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "1"
                                        },
                                        2: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "2"
                                        },
                                        3: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "3"
                                        },
                                        4: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "4"
                                        },
                                        5: {
                                            style: {
                                                color: "white"
                                            },
                                            label: "5"
                                        }
                                    }}
                                    onChange={this.onSliderChange}
                                    // onAfterChange={this.onSliderAfterChange}
                                    step={0.1}
                                    trackStyle={[trackStyle]}
                                    handleStyle={[handleStyle, handleStyle]}
                                    dotStyle={dotStyle}
                                />
                            </Col>
                            <Label xs="3" xsm="3">
                                <Form.Control
                                    style={{textAlign: "right", backgroundColor: "white"}}
                                    readOnly
                                    value={this.state.value}
                                />
                            </Label>
                        </Row>
                    </Col>
                </Row>
        );
    }
}