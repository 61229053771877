import { BUILD_ENVIRONMENT, COGNITO_REGION, COGNITO_USERPOOL_ID, COGNITO_APP_CLIENT_ID } from "../constants";

const location = window.location.origin + "/";

export const cognitoAuthConfig = {
    "domain": `users.${BUILD_ENVIRONMENT}.thefilter.com`,
    "scope": [
        "email",
        "openid",
        "TF-visualiser-API/read"
    ],
    "redirectSignIn": location,
    "redirectSignOut": location,
    "responseType": "code"
};

export const cognitoConfig = {
    Auth: {
        region: COGNITO_REGION,
        userPoolId: COGNITO_USERPOOL_ID,
        userPoolWebClientId: COGNITO_APP_CLIENT_ID,
        mandatorySignIn: false,
        authenticationFlowType: "USER_PASSWORD_AUTH",
        oauth: cognitoAuthConfig
    }
};
