import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import { SlotNames } from "../enums";
import SetupSlotGrid from "../components/slots/SetupSlotGrid";

type SlotsSetupState = {
    slots: Array<SlotElement>,
    slot?: SlotElement,
    slotCount: number,
    userId?: string,
    error: Error | null,
    previewLoaded: boolean
    previewLoading: boolean,
    slotSubmit: boolean,
    sortOrder: "slotId" | "slotName"
}

type SlotsSetupProperties = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}


export default class SlotsEditor extends React.Component<SlotsSetupProperties, SlotsSetupState> {

    constructor(props: SlotsSetupProperties) {
        super(props);

        this.state = {
            slots: this.demoFunctionToAddSlots(),
            slotCount: 4,
            error: null,
            previewLoaded: false,
            previewLoading: false,
            slotSubmit: false,
            sortOrder: "slotName"
        };
    }

    private saveNewDesign = async (
        customer: string,
        slots: SlotNames[]
    ): Promise<void> => {
        //post slots
    }

    private demoFunctionToAddSlots = (): Array<SlotElement> => {
        const slots = [
            {
                key: "slot-0",
                slotId: "882caad6-f7a0-4fda-aabc-d7a693980b6b",
                slotName: SlotNames.MostPopular,
                customerName: "Top Shows",
                time: "7 Days"
            },
            {
                key: "slot-1",
                slotId: "e85f8b98-8175-43d7-847a-7b1140e1343e",
                slotName: SlotNames.Latest,
                customerName: "Fresh and New Movie Releases",
                type: "Movies"
            },
            {
                key: "slot-2",
                slotId: "ef834577-2685-48ed-88cd-c77040f5bec6",
                slotName: SlotNames.Trending,
                customerName: "Trending Content",
                time: "24 Hours"
            },
            {
                key: "slot-3",
                slotId: "947e6e96-8cab-474a-a178-2fd1c72ba025",
                slotName: SlotNames.LatestInGenre,
                customerName: "Latest in [Genre]",
                genre: "Action"
            }
        ];

        return slots;
    };

    public render(): ReactNode {
        return (
            <div style={{ padding: "0px 15px" }}>
                <Container className="mw-100" key="composable-container" style={{ minHeight: "86vh" }}>
                    <SetupSlotGrid
                        groups={this.props.groups}
                        slots={this.state.slots}
                        fppSlots={this.state.slots}
                        count={this.state.slotCount}
                        customer={this.props.customerConfig.name}
                        saveCallback={this.saveNewDesign} />
                </Container>
            </div>
        );
    }
}