import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub } from "aws-amplify";
import * as qs from "qs";
import React, { ReactElement, ReactNode, RefObject } from "react";
import { Button, Form, Nav, Navbar, NavDropdown, InputGroup } from "react-bootstrap";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import logo from "./images/24i_logo_red.png";
import { getDefaultRoute, getRoutingForGroup } from "./config/Routing";
import Routes from "./Routes";
import Logger from "./utils/Logger";
import TimeoutComponent from "../src/components/TimeoutComponent";

import { getAvailableCustomers, getCustomerConfig, initialiseCustomerConfig } from "../src/config/Customers";
import CustomerDropdown from "../src/components/CustomerDropdown";
import { EPIX_FULL_AUTH } from "./constants";
import { initialisePresets } from "./config/Presets";

const AppContainer = styled.div`
    height: 100%;
`;

const StandaloneNavBar = styled(Navbar)`
    height: 30px;
`;

const StandaloneNavBarBrand = styled(Navbar.Brand)`
    line-height: 0;
`;

const AppNavItem = styled(Nav.Item)`
    font-size: 20px;
`;

const SignedInAs = styled.p`
    margin-right: 10px;
    margin-bottom: 0px;
    vertical-align: middle;
`;

const SignedInAsSmall = styled(SignedInAs)`
    font-size: 12px;
`;

const Username = styled.b`
    font-weight: 600;
`;

const Logo = styled.img`
    width: 70px;
`;

const LogoSmall = styled.img`
    width: 38px;
`;

const ButtonSmall = styled(Button)`
    height: 18px;
    font-size: 12px;
    line-height: 0;
`;

const MessageContainer = styled.div`
    margin-top: 10px;
    text-align: center;
`;

const CustomerRow = styled(InputGroup)`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

type AppState = {
    username: string,
    loggedIn: boolean,
    standalone: boolean,
    local: boolean,
    groups: string[],
    customerConfig: CustomerConfig | null,
    customer: string | null,
    activeKey: string
}

type AppProperties = RouteComponentProps;

class App extends React.Component<AppProperties, AppState> {

    private customerSelectorElement: RefObject<CustomerDropdown>;

    private historyUnlisten: (() => void) | null = null;

    constructor(props: AppProperties) {
        super(props);

        this.customerSelectorElement = React.createRef<CustomerDropdown>();

        let standalone = false;
        let local = false;
        const url = window.location.href;
        const qsParams = qs.parse(url.slice(url.lastIndexOf("?") + 1));
        if (qsParams.hasOwnProperty("standalone")) standalone = true;
        if (qsParams.hasOwnProperty("local")) local = true;

        this.state = {
            username: "",
            loggedIn: false,
            standalone,
            groups: [],
            local,
            customerConfig: null,
            customer: null,
            activeKey: ""
        };

        Logger.log(`Portal Version: ${process.env.REACT_APP_VERSION}`);

        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                case "cognitoHostedUI":
                    this.setState({
                        loggedIn: true,
                        username: data.username
                    });
                    break;
                case "signOut":
                    this.setState({ loggedIn: false });
                    break;
                default:
                    Logger.log(event, data);
                    break;
            }
        });

        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);

        this.checkAuthState();
    }

    public componentDidMount() {
        this.historyUnlisten = this.props.history.listen((location) => {
            this.setState({ activeKey: location.pathname });
        });
    }

    public componentWillUnmount() {
        if (this.historyUnlisten) {
            this.historyUnlisten();
        }
    }

    private async checkAuthState() {
        try {
            const userInfo = await Auth.currentUserPoolUser();
            const session = await Auth.currentSession();
            const groups = await session.getAccessToken().payload["cognito:groups"];
            if (session) {
                this.setState({
                    loggedIn: true,
                    username: userInfo.username,
                    groups
                }, async () => {
                    await initialiseCustomerConfig(groups.join(","));
                    const availableCustomers = getAvailableCustomers(this.state.groups);
                    if (availableCustomers.length > 0) {
                        const selectedCustomer = window.history.state?.customer || availableCustomers[0].name;
                        const customerConfig = getCustomerConfig(selectedCustomer);
                        await initialisePresets(groups.join(","), customerConfig.slug);
                        this.setState({
                            customerConfig,
                            customer: selectedCustomer
                        }, async () => {
                            const defaultActiveKey = window.location.pathname !== "/" ? window.location.pathname : getDefaultRoute(this.state.groups);
                            this.setState({ activeKey: defaultActiveKey });
                        });
                    }

                    // This has been moved to only apply for MGM+ and in production (as per their request)
                    // As the portal doesn't autosave and may lead to lost work if not aware
                    if (groups.includes(EPIX_FULL_AUTH) && process.env.BUILD_ENVIRONMENT === "production") {
                        const timeout = new TimeoutComponent({callback: this.signOut});
                        timeout.startTimer();
                    }

                });
            } else {
                this.setState({
                    loggedIn: false
                });
            }

        } catch (error) {
            if (error === "No current user") {
                this.signIn();
            }
            Logger.error("Error checking auth state: ", error);
        }
    }

    private signIn() {
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
    }

    private async signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            Logger.error("Error signing out: ", error);
        }
    }

    private createNavLinks(): ReactElement {
        const navLinks = getRoutingForGroup(this.state.groups).filter((route: RouteConfig) => {
            return !route.hide && this.state.groups?.some(group => route.groups.includes(group));
        }).map((route: RouteConfig, i: number) => {
            if (route.children) {
                return (
                    <NavDropdown key={i} title={route.displayName} id={route.path}>
                        {
                            route.children.filter((childRoute:RouteConfig) => {
                                if (childRoute.path === "/fallbackviewer" && this.state.customerConfig?.fallbackHost === undefined) {
                                    return false;
                                }
                                return true;
                            }).map((childRoute: RouteConfig, j: number) => {
                                return (
                                    <AppNavItem key={`${i}-${j}`} style={{ display: childRoute.hide }}>
                                        <Nav.Link as={Link} to={childRoute.path} eventKey={childRoute.path}>{childRoute.displayName}</Nav.Link>
                                    </AppNavItem>
                                );
                            })
                        }
                    </NavDropdown>
                );
            } else {
                return (
                    <AppNavItem key={i} style={{ display: route.hide }}>
                        <Nav.Link as={Link} to={route.path} eventKey={route.path}>{route.displayName}</Nav.Link>
                    </AppNavItem>
                );
            }
        });

        return (
            <Nav className="mr-auto" activeKey={this.state.activeKey}>
                {navLinks}
            </Nav>
        );
    }

    private setCustomer = async (customer: string): Promise<void> => {
        if (customer !== this.state.customer) {

            const customerConfig = getCustomerConfig(customer);

            await initialisePresets(this.state.groups.join(","), customerConfig.slug);
            window.history.replaceState({ ...window.history.state, customer }, "");

            this.setState({
                customer,
                customerConfig
            });
        }
    }

    public render(): ReactNode {
        if (this.state.customerConfig === null || this.state.customer === null) {
            return (
                <AppContainer key="app">
                     <Navbar bg="dark" variant="dark" expand="md" sticky="top">
                        <Navbar.Brand href="/">
                            <Logo src={logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Nav>
                            <CustomerRow col={1}>
                            </CustomerRow>
                        </Nav>
                    </Navbar>
                    <MessageContainer>
                        <p>Loading...</p>
                    </MessageContainer>
                </AppContainer>
            );
        }
        const hasGroups = this.state.groups.length > 0;
        if (this.state.standalone) {
            return (
                <AppContainer key="app">
                    <StandaloneNavBar bg="dark" expanded={false} variant="dark" sticky="top">
                        <StandaloneNavBarBrand href="/">
                            <LogoSmall src={logo} />
                        </StandaloneNavBarBrand>
                        <Nav>
                            <CustomerRow col={1}>
                                <CustomerDropdown
                                    ref={this.customerSelectorElement}
                                    groups={this.state.groups}
                                    callback={this.setCustomer}
                                    standalone={this.state.standalone}
                                    initialValue={this.state.customer!}
                                />
                            </CustomerRow>
                        </Nav>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        {this.state.loggedIn &&
                            <Navbar.Collapse>
                                <Nav className="mr-auto"></Nav>
                                <Form inline>
                                    <SignedInAsSmall>Signed in as: <Username>{this.state.username}</Username></SignedInAsSmall>
                                    <ButtonSmall variant="secondary" onClick={this.signOut}>Logout</ButtonSmall>
                                </Form>
                            </Navbar.Collapse>
                        }
                        {!this.state.loggedIn &&
                            <Navbar.Collapse>
                                <Nav className="mr-auto" />
                                <ButtonSmall onClick={this.signIn}>Log In</ButtonSmall>
                            </Navbar.Collapse>
                        }
                    </StandaloneNavBar>
                    {this.state.loggedIn && hasGroups &&
                        <Routes
                            standalone={this.state.standalone}
                            local={this.state.local}
                            groups={this.state.groups}
                            username={this.state.username}
                            customerConfig={this.state.customerConfig!}
                            setCustomerCallback={this.setCustomer}
                        />
                    }
                    {!this.state.loggedIn &&
                        <MessageContainer>
                            <p>Log in to use the Portal</p>
                            <Button onClick={this.signIn}>Log In</Button>
                        </MessageContainer>
                    }
                </AppContainer>
            );
        } else {
            return (
                <AppContainer key="app">
                    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
                        <Navbar.Brand href="/">
                            <Logo src={logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Nav>
                            <CustomerRow col={1}>
                                <CustomerDropdown
                                    ref={this.customerSelectorElement}
                                    groups={this.state.groups}
                                    callback={this.setCustomer}
                                    initialValue={this.state.customer!}
                                />
                            </CustomerRow>
                        </Nav>
                        {this.state.loggedIn &&
                            <Navbar.Collapse>
                                {this.createNavLinks()}
                                <Form inline>
                                    <SignedInAs className="navbar-text">Signed in as: <Username>{this.state.username}</Username></SignedInAs>
                                    <Button variant="secondary" onClick={this.signOut}>Logout</Button>
                                </Form>
                            </Navbar.Collapse>
                        }
                        {!this.state.loggedIn &&
                            <Navbar.Collapse>
                                <Nav className="mr-auto" />
                                <Button onClick={this.signIn}>Log In</Button>
                            </Navbar.Collapse>
                        }
                    </Navbar>

                    {this.state.loggedIn && hasGroups &&
                        <Routes
                            local={this.state.local}
                            groups={this.state.groups}
                            username={this.state.username}
                            customerConfig={this.state.customerConfig!}
                            setCustomerCallback={this.setCustomer}
                        />
                    }
                    {!this.state.loggedIn &&
                        <MessageContainer>
                            <p>Log in to use the Portal</p>
                            <Button onClick={this.signIn}>Log In</Button>
                        </MessageContainer>
                    }
                </AppContainer>
            );
        }
    }
}

export default withRouter(App);