import { Component } from "react";

type TimeoutProps = {
    callback: () => void
}

class TimeoutComponent extends Component<TimeoutProps> {

    private INACTIVE_TIME = 900000; // 15 minutes
    private INACTIVE_RESET_EVENTS = [
        "click",
        "mousemove",
        "keydown",
        "scroll",
        "touchmove"
    ];
    private timer: number | undefined;

    constructor(props: TimeoutProps){
      super(props);

      this.userTimeout = this.userTimeout.bind(this);
      this.startTimer = this.startTimer.bind(this);

        // Set-up inactivity timer
        this.INACTIVE_RESET_EVENTS.forEach((eventType: string) => {
            document.addEventListener(eventType, this.userTimeout);
        });
    }

    public startTimer(): void {
        this.timer = window.setTimeout(() => {
            this.props.callback();
        }, this.INACTIVE_TIME);
    }

    public userTimeout():void {
      window.clearTimeout(this.timer);
      this.startTimer();
    }
  }

export default TimeoutComponent;