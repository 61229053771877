import React, { ReactNode, RefObject } from "react";
import {Alert, Button, Col, Container, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    FileEarmarkArrowDownFill as SaveIcon
} from "react-bootstrap-icons";
import styled from "styled-components";

import { createOrUpdateEntities, entitySearch } from "../../utils/Requests";
import Logger from "../../utils/Logger";
import EntityForm from "./EntityForm";

const EntityFieldsCol = styled(Col)`
    border-radius: 3px;
    margin: 10px 0;
`;

type CreateNewEntityModalState = {
    error?: Error,
    showError?: boolean,
    showSuccess?: boolean,
    errorMsg?: string
}

type CreateNewEntityModalProperties = {
    show?: boolean,
    closeCallback: () => void
}

export default class CreateNewEntityModal extends React.Component<CreateNewEntityModalProperties, CreateNewEntityModalState> {

    private entityFormElement: RefObject<EntityForm>;

    constructor(props: CreateNewEntityModalProperties) {
        super(props);

        this.entityFormElement = React.createRef<EntityForm>();

        this.state = {
            showError: false,
            showSuccess: false,
            errorMsg: ""
        };
    }

    private saveNewEntity(): void {
        this.setState({
            showError: false,
            errorMsg: "",
            showSuccess: false
        });

        if (this.entityFormElement.current) {
            const createdEntity = this.entityFormElement.current?.getEntity(true);

            if (createdEntity.type === ""){
                this.setState({
                    showError: true,
                    errorMsg: "Error: Please select a Type"
                });
            }
            else if(createdEntity.name === ""){
                this.setState({
                    showError: true,
                    errorMsg: "Error: Please enter a Name"
                });
            }
            else {
                const searchResults: Promise<Entity[]> = this.requestItems(createdEntity.type, createdEntity.name);
                searchResults.then( (values: Entity[]) => this.checkEntityExists(values, createdEntity));
            }
        }
    }

    private checkEntityExists(searchResults: Entity[], createdEntity: Entity): void {
        const entityToCreate: any[] = [];
        if (typeof searchResults[0] !== "undefined" && searchResults[0].name.toLowerCase() === createdEntity.name.toLowerCase()) {
                this.setState({
                    showError: true,
                    errorMsg: `Error: Entity "${createdEntity.name}" of type "${createdEntity.type}" already in Use`
                });
            }
        else {
            entityToCreate.push(createdEntity);
            createOrUpdateEntities(entityToCreate);
            this.closeSuccess();
        }
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: true
        });

        setTimeout(() => {
            this.setState({showSuccess: false});
        }, 2000);

        setTimeout(() => {
            this.props.closeCallback();
        }, 2000);
    }

    private resetErrorStatus = (): void => {
        this.setState({
            showError: false,
            errorMsg: ""
        });

    }
    public async requestItems(type: string, name: string): Promise<Entity[]>  {
        const searchResults: any[] = [];

        try {
            const searchResults = await entitySearch(
                type,
                name
            );

            return searchResults;

        } catch (err) {
            if (err instanceof Error) {
                this.handleError(err);
            }
        }

        return searchResults;
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            error,
            showError: true,
            errorMsg: error.message
        });
    }

    public render(): ReactNode {
        return (
            <Modal
                show={this.props.show}
                onHide={()=> { this.props.closeCallback(); }}
                centered
                dialogClassName="modal-70w"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create New Entity</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container className="mw-100" key="metadata-container">
                        <Row>
                            <EntityFieldsCol className="mx-auto">
                                <Row>
                                    <Col>
                                        <EntityForm ref={this.entityFormElement} focusCallback={this.resetErrorStatus} create/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={11}>
                                        <Alert variant='danger' show={this.state.showError}>
                                            {this.state.errorMsg}
                                        </Alert>
                                        {this.state.showSuccess &&
                                            <Alert variant='success' show={this.state.showSuccess}>
                                                Success creating new entity
                                            </Alert>
                                        }
                                    </Col>
                                    <Col xs={1} className="d-flex justify-content-end">
                                        <OverlayTrigger overlay={<Tooltip id="Create">Create New Entity</Tooltip>}>
                                            <Button variant="primary" type="button" onClick={()=> { this.saveNewEntity() ;}}>
                                                <SaveIcon size={20}/>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </EntityFieldsCol>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}