export const InitialModelParameters: Record<string, Array<ModelVersion>> = {
    "More Like This": [
        {
            "name": "mlt_20220926",
            "weights": [
                {
                    "name": "Description",
                    "value": 3
                },
                {
                    "name": "Franchise",
                    "value": 1
                },
                {
                    "name": "Genre",
                    "value": 0.5
                },
                {
                    "name": "Mood",
                    "value": 0.5
                },
                {
                    "name": "Tag",
                    "value": 2
                },
                {
                    "name": "Actor",
                    "value": 0.2
                },
                {
                    "name": "Recency (Date Published)",
                    "value": 0.4
                },
                {
                    "name": "Popularity",
                    "value": 1
                }
            ]
        }
    ],
    "Recommended For You": [
        {
            "name": "rfy_20230220",
            "weights": [
                {
                    "name": "Description (TFIDF)",
                    "value": 3
                },
                {
                    "name": "Description (BERT)",
                    "value": 3
                },
                {
                    "name": "Franchise OneHot",
                    "value": 1
                },
                {
                    "name": "Genre (Combined) OneHot",
                    "value": 1.1
                },
                {
                    "name": "Mood OneHot",
                    "value": 0.5
                },
                {
                    "name": "Tag OneHot",
                    "value": 2
                },
                {
                    "name": "Director OneHot",
                    "value": 0.2
                },
                {
                    "name": "Actor OneHot",
                    "value": 0.2
                },
                {
                    "name": "thing datepublished OneHot",
                    "value": 0.4
                },
                {
                    "name": "Rating Int",
                    "value": 0.25
                },
                {
                    "name": "Is Kids",
                    "value": 2
                }
            ]
        }
    ]
};