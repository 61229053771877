import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

type RemoveBlockingModalProperties = {
    showModal: boolean,
    removeCallback: () => void,
    closeCallback: () => void,
    blockedItem: BlockedItem
}

export default class RemoveBlockingModal extends React.Component<RemoveBlockingModalProperties> {

    private closeModal = (): void => {
        this.props.closeCallback();
    }

    private approve = (): void => {
        this.props.removeCallback();
    }

    public render(): ReactNode {
        const { blockedItem } = this.props;

        return (
            <Modal
                show={this.props.showModal}
                onHide={this.closeModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Remove Item Block?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Do you want to remove the current block for: <b>{blockedItem!.thingName}({blockedItem!.thingId})</b> on slot: {blockedItem!.slotId}?
                        <br/>
                        <b>This cannot be undone</b>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>Cancel</Button>
                    <Button variant="danger" onClick={this.approve}>Remove</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
