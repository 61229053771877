import React, { ReactNode, RefObject } from "react";
import { Col, Row, Form, Container, InputGroup, FormControl } from "react-bootstrap";
import styled from "styled-components";
import BrowseView from "../components/browse/BrowseView";
import RangeSlider from "../components/filters/RangeSlider";
import { getSearchPreset } from "../config/Presets";
import { getGenres } from "../utils/Requests";
import { FilterUITypes } from "../enums";
import Logger from "../utils/Logger";
import FilterList from "../components/filters/FilterList";

const StickyColumn = styled(Col)`
    position: fixed;
    top: calc(108px + 1rem);
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: scroll;

    /* Hide the scrollbar */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    min-width: 75px;
`;

const FilterContainer = styled.div`
    border-radius: 3px;
    padding: 10px;
    margin-left: 10px;
`;

const FilterLabel = styled.h5`
    text-align:center;
`;

const SearchRow = styled(Row)`
    align-items: center;
    margin-left: 5px;
`;

const SearchLabel = styled(Form.Label)`
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
`;

const SearchBar = styled(FormControl)`
    width: 20%;
    margin-right: 10px;
    margin-left: auto;
`;

const FilterComponents = styled.div`
    margin-top: 5px;
`;

const SearchFilterRow = styled(Form.Row)`
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    min-height: 38px;
`;

const ContentColumn = styled(Col)`
    margin-left: 25%;
    margin-right: 10%;
`;

type BrowseViewerState = {
    searchPreset?: Preset,
    isLoading: boolean,
    isLoaded: boolean,
    error: Error | null,
    searchParameters: string,
    genres: Array<string>,
    additionalParameters?: Record<string, string>,
    items: Array<Record<string, any>>,
    aToZ: boolean,
    zToA: boolean,
    sortValue: string
}

type BrowseViewerProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    metadataAccess?: boolean,
    customerConfig: CustomerConfig
}

export default class BrowseViewer extends React.Component<BrowseViewerProperties, BrowseViewerState> {
    private filterRefs: Array<RefObject<FilterList | RangeSlider>>;
    private availablePreset: Preset | undefined;
    private presetGenres: Array<string> = [];

    genreInput: any;
    yearInput: any;
    ratingInput: any;

    constructor(props: BrowseViewerProperties) {
        super(props);
        this.filterRefs = [];
        this.availablePreset = getSearchPreset(this.props.customerConfig.slug);

        const genreFilter = this.availablePreset?.filters?.filter((filter) => { return filter.name === "Genres"; });

        if (genreFilter !== undefined) {
            if (genreFilter.length > 0) {
                this.presetGenres = genreFilter[0].values || [];
            }
        }

        this.state = {
            searchPreset: this.availablePreset,
            searchParameters: "",
            items: [],
            isLoading: false,
            error: null,
            isLoaded: false,
            aToZ: false,
            zToA: false,
            sortValue: "Unsorted",
            genres: []
        };
    }


    public componentDidMount(): void {
        this.getGenres();
        this.update();
    }

    private update = (): void => {
        this.setState({
            isLoading: true,
            isLoaded: false
        });
    };

    private getGenres = async (): Promise<void> => {
        this.setState({
            genres: []
        });
        if (this.props.customerConfig) {
            await getGenres(this.props.customerConfig).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        const genresList = response.items[0]["genres"] || this.presetGenres;
                        if (!genresList.includes("All Genres")) genresList.unshift("All Genres");
                        this.setState({
                            genres: genresList
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private setQueryStringParam = (qsObject: Record<string, string>): void => {
        const { additionalParameters } = this.state;

        // Update existing parameters only if the user changes the value
        const newAdditionalParameters = Object.assign({}, additionalParameters);
        for (const [key, value] of Object.entries(qsObject)) {
            if (key === "category" && value === "All Genres") {
                if (newAdditionalParameters[key]) {
                    delete newAdditionalParameters[key];
                }
            } else if (key === "typename" && value === "All Types") {
                if (newAdditionalParameters[key]) {
                    delete newAdditionalParameters[key];
                }
            } else if (newAdditionalParameters[key] !== value) {
                newAdditionalParameters[key] = value;
            }
        }

        this.setState({ additionalParameters: newAdditionalParameters }, this.update);
    }

    private buildComponent(filter: DropdownFilter | RangeSliderFilter, i: number): ReactNode {
        switch (filter.type) {
            case FilterUITypes.Dropdown: {
                const ddFilterRef = React.createRef<FilterList>();
                this.filterRefs.push(ddFilterRef);

                return (
                    <SearchFilterRow key={`filter-${i}`} style={{ marginBottom: "10px", borderTop: "solid white thin" }}>
                        <FilterList
                            ref={ddFilterRef}
                            filter={filter as DropdownFilter}
                            callback={this.setQueryStringParam}
                        />
                    </SearchFilterRow>
                );
            }
            case FilterUITypes.RangeSlider: {
                const rsFilterRef = React.createRef<RangeSlider>();
                this.filterRefs.push(rsFilterRef);
                return (
                    <SearchFilterRow key={`filter-${i}`}>
                        <RangeSlider
                            ref={rsFilterRef}
                            filter={filter as RangeSliderFilter}
                            callback={this.setQueryStringParam}
                        />
                    </SearchFilterRow>
                );
            }
            case FilterUITypes.Slider: {
                return <p>{filter.name} - Slider</p>;
            }
            default: {
                Logger.error("Unknown filter type");
                break;
            }
        }
    }

    private buildFilterComponents = () => {
        const { searchPreset } = this.state;

        this.filterRefs = [];

        const filters = searchPreset?.filters || [];

        const existingGenreFilter = searchPreset?.filters?.some((filter) => { return filter.name === "Genres"; });

        if (this.state.genres.length > 0 && !existingGenreFilter) {
            filters.unshift(
                {
                    id: "category-dropdown",
                    name: "Genres",
                    type: FilterUITypes.Dropdown,
                    qsParam: "category",
                    defaultValue: "All Genres",
                    values: this.state.genres
                }
            );
        }

        return filters?.map((filter: DropdownFilter | RangeSliderFilter, i) => {
            return this.buildComponent(filter, i);
        });
    }

    private handleSort = (e: React.ChangeEvent<HTMLInputElement>) => {
        const sortValue = e.currentTarget.value;

        switch (sortValue) {
            case "AtoZ":
                this.setState({
                    sortValue,
                    aToZ: true,
                    zToA: false
                });
                break;
            case "ZtoA":
                this.setState({
                    sortValue,
                    aToZ: false,
                    zToA: true
                });
                break;
            default:
                this.setState({
                    sortValue: "Unsorted",
                    zToA: false,
                    aToZ: false
                });
                break;
        }
    }

    private handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { additionalParameters } = this.state;
        const { value } = e.target;

        const newAdditionalParameters = Object.assign({}, additionalParameters);

        if (value === "") {
            delete newAdditionalParameters.alpha;
        } else {
            newAdditionalParameters.alpha = value.toLowerCase();
        }

        this.setState({ additionalParameters: newAdditionalParameters }, this.update);
    }


    public render(): ReactNode {
        const { customerConfig, username, groups, metadataAccess } = this.props;
        const { additionalParameters, aToZ, zToA, sortValue } = this.state;
        const shouldRender = customerConfig || additionalParameters || aToZ || zToA;

        return (
            <div style={{ overflowX: "hidden" }}>
                <Container className="mw-100">
                    <Row>
                        <StickyColumn md={3}>
                            <FilterContainer style={{ background: "#282828" }}>
                                <Row>
                                    <Col>
                                        <FilterLabel>Filter Options:</FilterLabel>
                                    </Col>
                                </Row>
                                <SearchRow>
                                    <SearchLabel>Alpha Search:</SearchLabel>
                                    <SearchBar
                                        type="text"
                                        id="search"
                                        placeholder="a.b.c."
                                        onChange={this.handleAlphaChange}
                                        maxLength={1}
                                    />
                                </SearchRow>
                                <FilterComponents>
                                    {this.buildFilterComponents()}
                                </FilterComponents>
                            </FilterContainer>
                        </StickyColumn>
                        <ContentColumn md={9} className="mr-3">
                            <Row className="d-flex justify-content-end" style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Col md={2} className="d-flex">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Sort By:</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            id="dropdown-slot"
                                            title="Slot"
                                            style={{ "padding": "0.375rem" }}
                                            value={sortValue}
                                            onChange={this.handleSort}
                                        >
                                            <option value="none" key="none">Unsorted</option>
                                            <option value="AtoZ" key="AtoZ">A to Z</option>
                                            <option value="ZtoA" key="ZtoA">Z to A</option>
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                {shouldRender && (
                                    <BrowseView
                                        customer={customerConfig}
                                        username={username}
                                        groups={groups}
                                        metadataAccess={metadataAccess}
                                        addParams={additionalParameters}
                                        aToZ={aToZ}
                                        zToA={zToA}
                                    />
                                )}
                                </Col>
                            </Row>
                        </ContentColumn>
                    </Row>
                </Container>
            </div>
        );
    }
}