import React, { ReactNode } from "react";
import styled from "styled-components";

const FilterListContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: block;
  }
`;

const FilterListItem = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  color: grey;
  cursor: pointer;
  font-size: 16px;
  text-align: left;

  &.selected {
    color: white;
  }
`;

type FilterListProperties = {
    filter: DropdownFilter,
    callback: (qsParam: Record<string, string>) => void
};

type FilterListState = {
    selectedValues: Set<string>
};

export default class FilterList extends React.Component<FilterListProperties, FilterListState> {
    constructor(props: FilterListProperties) {
        super(props);

        const { filter } = props;
        const initialValue = filter.values?.[0] ?? "";

        this.state = {
            selectedValues: new Set<string>([initialValue])
        };
    }

    public reset(): void {
        this.setState({
            selectedValues: new Set<string>()
        });
    }

    public handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const value = event.currentTarget.value;
        const selectedValues = new Set<string>([value]);

        this.setState({ selectedValues });

        const { filter, callback } = this.props;

        if (filter.qsParam) {
            const qsObj: Record<string, string> = { [filter.qsParam]: [...selectedValues].join(",") };
            callback(qsObj);
        }
    };

    public render(): ReactNode {
        const { filter } = this.props;
        const { selectedValues } = this.state;

        return (
            <FilterListContainer>
                {filter.values?.map((value, i) => (
                    <FilterListItem
                        key={i}
                        value={value}
                        onClick={this.handleOnClick}
                        className={selectedValues.has(value) ? "selected" : ""}
                    >
                        {value}
                    </FilterListItem>
                ))}
            </FilterListContainer>
        );
    }
}
