import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

type SaveSlotModalProperties = {
    slotId: string,
    slotName: string,
    slotType?: string,
    showModal: boolean,
    jsonData?: string,
    closeCallback: (show: boolean) => void,
    saveCallback: () => void
}

export default class SaveSlotModal extends React.Component<SaveSlotModalProperties> {

    private validateJson = (): boolean => {
        if (!this.props.jsonData) {
            return false;
        }

        try {
            JSON.parse(this.props.jsonData);
            return true;
        } catch (e) {
            return false;
        }
    };

    public render(): ReactNode {
        const isJsonValid = this.validateJson();
        return (
            <Modal show={this.props.showModal} onHide={() => { this.props.closeCallback(false); }}>
                <Modal.Header closeButton>
                    {isJsonValid ? (
                        <Modal.Title>Save slot definition</Modal.Title>
                    ) : (
                        <Modal.Title style={{ fontSize: "20px" }}>
                            <span style={{ color: "red", marginRight: "8px", fontSize: "24px" }}>ERROR</span>
                            invalid JSON data
                        </Modal.Title>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {isJsonValid ? (
                        <div>
                            Save slot <b>{this.props.slotName}</b> of type <b>{this.props.slotType}</b><br />
                            with ID: <b>{this.props.slotId}</b><br />
                            <br />
                            <b>WARNING:</b> Saving will overwrite any existing slot<br />
                        </div>
                    ) : (
                        <div>
                            The JSON data you entered is not valid. Please correct the errors before saving.
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.props.closeCallback(false); }}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => { isJsonValid && this.props.saveCallback(); this.props.closeCallback(false); }}
                        disabled={!isJsonValid}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}