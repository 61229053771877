import React, { ReactNode } from "react";
import { Row, Col, Alert, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import * as utils from "../../utils/Utils";

type EntityFormState = {
    id: string,
    name: string,
    type: string,
    alternateName: string[],
    description: string,
    notes: string,
    namespace: string
}

type EntityFormProperties = {
    create?: boolean
    entity?: Entity,
    changeCallback?: () => void,
    focusCallback?: () => void
}

export default class EntityForm extends React.Component<EntityFormProperties, EntityFormState> {

    constructor(props: EntityFormProperties) {
        super(props);

        this.state = {
            id: "",
            name: "",
            type: "",
            alternateName: [],
            namespace: "",
            description: "",
            notes: ""
        };
    }

    public componentDidUpdate(prevProps: EntityFormProperties): void {
        if (this.props.entity && this.props.entity !== prevProps.entity) {
            this.setStateFromEntity(this.props.entity);
        }
    }

    public reset(): void {
        if (this.props.entity) {
            this.setStateFromEntity(this.props.entity);
        }
    }

    public getEntity(excludeId?: boolean): Entity {
        const { id, name, type, alternateName, namespace, description, notes } = this.state;

        const entity: Entity = {
            id,
            name,
            type,
            alternateName,
            namespace,
            description,
            notes
        };

        if (excludeId) delete entity.id;

        return entity;
    }

    private changeCallback = () => {
        if (this.props.changeCallback) {
            this.props.changeCallback();
        }
    }

    private focusCallback = () => {
        if (this.props.focusCallback) {
            this.props.focusCallback();
        }
    }

    private setStateFromEntity(entity: Entity): void {
        if (entity.id) {
            this.setState({
                id: entity.id,
                name: entity.name,
                type: entity.type,
                alternateName: entity.alternateName || [],
                namespace: entity.namespace || "",
                description: entity.description || "",
                notes: entity.notes || ""
            });
        }
    }

    private setValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        }, this.changeCallback);
    }

    private setAlternateName = (event: any[]): void => {
        const newAlternateName: any = [];
        event.forEach(item => {
             newAlternateName.push(item.label) ;
        });
        this.setState({
            alternateName: newAlternateName
         }, this.changeCallback);
    }


    public render(): ReactNode {
        return (
            <>
                <Row>
                    <Col>
                        <Alert variant={"secondary"}>
                            Please refer to the <a href="https://24imedia.atlassian.net/wiki/spaces/PRDDATADATASCI/pages/3017572553/Entity+Guidebook">Entity Guidebook</a> when creating or modifying entities
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="entity-name-input">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                size="lg"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.setValue}
                                onFocus={this.focusCallback}
                                autoComplete="off"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="entity-type-input">
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                as="select"
                                title="Count"
                                name="type"
                                value={this.state.type}
                                onChange={this.setValue}
                                onFocus={this.focusCallback}
                            >
                                { this.props.create &&
                                    <option disabled value={""} key={""}>Please Select An Entity Type</option>
                                }
                                <option value={"character"} key={"Character"}>Character</option>
                                <option value={"franchise"} key={"Franchise"}>Franchise</option>
                                <option value={"genre"} key={"Genre"}>Genre</option>
                                <option value={"issue"} key={"Issue"}>Issue</option>
                                <option value={"mood"} key={"Mood"}>Mood</option>
                                <option value={"people"} key={"People"}>People</option>
                                <option value={"tag"} key={"Tag"}>Tag</option>
                                <option value={"group"} key={"Group"}>Group</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="entity-namespace-input">
                            <Form.Label>Namespace</Form.Label>
                            <Form.Control
                                type="text"
                                name="namespace"
                                value={this.state.namespace}
                                onChange={this.setValue}
                                onFocus={this.focusCallback}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="entity-alternatename-input">
                            <Form.Label>Alternate Names</Form.Label>
                            <Typeahead
                                id="entity-alternatename-typeahead"
                                allowNew
                                multiple
                                selected={utils.formatTypeAheadItems(this.state.alternateName)}
                                options={[]}
                                newSelectionPrefix="Add a new alternateName: "
                                placeholder="Add alternative names for this entity..."
                                onChange={this.setAlternateName}
                                onFocus={this.focusCallback}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="entity-description-input">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={this.state.description}
                                name="description"
                                onChange={this.setValue}
                                onFocus={this.focusCallback}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="entity-notes-input">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={this.state.notes}
                                name="notes"
                                onChange={this.setValue}
                                onFocus={this.focusCallback}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }
}