import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Mic as SpeechIcon, MicMute as MuteIcon } from "react-bootstrap-icons";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import Logger from "../utils/Logger";

interface IProps {
    searchCallback: (arg0: string) => void;
    resetCallback: () => void;
    small?: boolean
}

// eslint-disable-next-line
const SpeechRec = ({searchCallback, resetCallback, small}: IProps) => {
    const [speechEnabled, setSpeech] = useState(Boolean);
    const commands = [
        {
            command: [
                "Search for *",
                "Show me films starring *",
                "Show me film starring *"
            ],
            callback: (term: string) => {
                searchCallback(term);
                resetTranscript();
            }
        },
        {
            command: [
                "* mood for *",
                "* watch something *",
                "* something *"
            ],
            callback: (_: string, term: string) => {
                searchCallback(term);
                resetTranscript();
            }
        },
        {
            command: [
                "Show me something random",
                "Random please"
            ],
            callback: () => {
                const terms = [
                    "comedy", "Bernie", "Monster", "Snake", "Nicolas Cage", "Avengers",
                    "Tom Cruise", "Jerry","Music", "Voice", "Blade", "Cat",
                    "Money", "Mystery", "search", "Iron-man", "Spider",
                    "eagle", "hotel", "time", "hello", "music",
                    "monkey","crocodile","bake off","the walking dead",
                    "spiderman", "big cats",
                    "Will Smith", "Disney", "Aladdin", "creepy", "valentine"
                ];
                searchCallback(terms[Math.floor(Math.random() * Math.floor(terms.length))]);
                resetTranscript();
            }
        },
        {
            command: "reset",
            callback: () => {
                resetCallback();
            }
        },
        {
            command: "Stop Listening",
            callback: () => {
                SpeechRecognition.stopListening();
                setSpeech(false);
            }
        }
    ];
    const {
        interimTranscript,
        finalTranscript,
        resetTranscript
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        if (finalTranscript !== "") {
            Logger.log("Got final result:", finalTranscript);
        }
    }, [interimTranscript, finalTranscript]);

    const listenContinuously = () => {
        setSpeech(true);
        SpeechRecognition.startListening({
            continuous: true,
            language: "en-GB"
        });
    };

    const stopListening = () => {
        setSpeech(false);
        SpeechRecognition.stopListening();
    };

    return (
        <OverlayTrigger
        placement="auto"
        overlay={
            <Tooltip id="voice-tooltip">
                Enable VoiceSearch <br/>
                Try the following commands:
                "Search for *", <br/>
                "Show me films starring *", <br/>
                "I'm in the mood for *", <br/>
                "I want to watch something *", <br/>
                "Show me something random"
            </Tooltip>}>
            <Button variant={speechEnabled ? "warning" : "primary"}
                    type="button"
                    size={small ? "sm" : undefined}
                    style={small ? {width: "47px"}: {}}
                    onClick={speechEnabled ? stopListening : listenContinuously}>
                {!small && (speechEnabled ? <MuteIcon size="22"/> : <SpeechIcon size="22"/>) }
                {small && (speechEnabled ? <MuteIcon /> : <SpeechIcon />) }
            </Button>
        </OverlayTrigger>
    );
};

export default SpeechRec;