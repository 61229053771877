import React, { ReactNode } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import sample from "../images/analytics_example.png";
import { getQuickSightURL } from "../utils/Requests";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import Logger from "../utils/Logger";
import { BUILD_ENVIRONMENT } from "../constants";

type AnalyticsProperties = {
    standalone?: boolean;
    groups: string[];
    username: string;
    customerConfig: CustomerConfig;
};

class Analytics extends React.Component<AnalyticsProperties> {
    public componentDidMount(): void {
        if (BUILD_ENVIRONMENT === "production") {
            this.setupQuicksight();
        }
    }

    private async setupQuicksight(): Promise<void> {
        const quicksightUrl = await getQuickSightURL(this.props.customerConfig);

        const embeddingContext = await createEmbeddingContext({
            onChange: (changeEvent, metadata) => {
                Logger.log("Context received a change", changeEvent, metadata);
            }
        });

        const frameOptions = {
            url: quicksightUrl["EmbedUrl"],
            container: "#experience-container",
            height: "750px",
            width: "1200px",
            onChange: (changeEvent: { eventName: any }, metadata: any) => {
                switch (changeEvent.eventName) {
                    case "FRAME_MOUNTED": {
                        Logger.log(
                            "Do something when the experience frame is mounted."
                        );
                        break;
                    }
                    case "FRAME_LOADED": {
                        Logger.log(
                            "Do something when the experience frame is loaded."
                        );
                        break;
                    }
                }
            }
        };

        const contentOptions = {
            parameters: [],
            locale: "en-US",
            sheetOptions: {
                initialSheetId: "<YOUR_SHEETID>",
                singleSheet: false,
                emitSizeChangedEventOnSheetChange: false
            },
            toolbarOptions: {
                export: false,
                undoRedo: false,
                reset: false
            },
            attributionOptions: {
                overlayContent: false
            }
        };

        await embeddingContext.embedDashboard(frameOptions, contentOptions);
    }

    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="analytics">
                    <Row>
                        <Col md style={{ textAlign: "center" }}>
                            {BUILD_ENVIRONMENT === "production" && (
                                <div id="experience-container"></div>
                            )}
                            {BUILD_ENVIRONMENT !== "production" && (
                                <>
                                    <h5>Coming Soon</h5>
                                    <Image src={sample} />
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Analytics;
