import React, { ReactNode, RefObject } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import styled from "styled-components";
import * as Icons from "react-bootstrap-icons";

import { ResultGrid } from "../components/ResultGrid";
import { getHostURL, getItemMetadata } from "../utils/Requests";

import { getAvailablePresets } from "../config/Presets";
import * as constants from "../constants";
import { seedIdCheck } from "../utils/Utils";
import RandomField from "../components/fields/RandomField";
import SeedAndUserHistoryPanel from "../components/SeedAndUserHistoryPanel";
import SeedInfo from "../components/SeedInfo";
import { RouteComponentProps, withRouter } from "react-router-dom";

const FormEntry = styled.div`
    padding: 2pt;
    width: 100%;
    text-align: center;
`;

const SectionHeader = styled.h5`
    margin-top: 10px;
    text-align: center;
    font-size: 1.2rem;
`;

const FieldName = styled(InputGroup.Text)`

`;

const FieldValue = styled(Form.Control)`
    padding: 0.375rem 0.375rem;
`;

type TestToolState = {
    preset: Preset,
    hostName: string,
    slotId: string,
    userId: string,
    parameters: string,
    seedId: string,
    seedItem?: Record<string, any>,
    lookupItems?: boolean,
    requestType: string,
    showSeedAndHistoryPanel: boolean
}

type TestToolProps = {
    local: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface TestToolProperties extends TestToolProps, RouteComponentProps { }

class TestTool extends React.Component<TestToolProperties, TestToolState> {

    private resultGridElement: RefObject<ResultGrid>;
    private availablePresets: Preset[];

    constructor(props: TestToolProperties) {
        super(props);
        this.resultGridElement = React.createRef<ResultGrid>();

        this.availablePresets = getAvailablePresets(this.props.groups, this.props.local);
            // .filter((preset) => preset.display.startsWith(this.props.customerConfig.name) || (preset.local && this.props.local));

        const initialPreset = this.getInitialPreset();
        let hostName = getHostURL(this.props.customerConfig);
        if (initialPreset.local) {
            hostName = "http://127.0.0.1:8080";
        }

        this.state = {
            preset: initialPreset,
            slotId: initialPreset.slotId,
            userId: initialPreset.userId,
            hostName,
            parameters: initialPreset.parameters,
            seedId: initialPreset.seedId ? initialPreset.seedId : "",
            lookupItems: initialPreset.lookupItems,
            requestType: constants.GET_REQUEST,
            showSeedAndHistoryPanel: false
        };
    }

    public componentDidMount(): void {
        if (this.state.seedId) {
            this.getSeedInfo();
        }
    }

    private getInitialPreset(): Preset {
        // Return first matching local or non-local preset, or first preset if no matches
        if (this.props.local) {
            return this.availablePresets.find((preset) => { return preset.local; }) || this.availablePresets[0];
        } else {
            return this.availablePresets.find((preset) => { return !preset.local; }) || this.availablePresets[0];
        }
    }

    private setSlotId = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            slotId: event.currentTarget.value
        });
    }

    private setUserId = (callbackValue: string, submit: boolean) => {
        this.setState({
            userId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setHostName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            hostName: event.currentTarget.value
        });
    }

    private setParameters = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            parameters: event.currentTarget.value
        });
    }

    private setSeedId = (callbackValue: string, submit: boolean) => {
        this.setState({
            seedId: callbackValue
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private setFormValues = (event: any): void => {
        const lookupValue = event.currentTarget.options[event.currentTarget.value].text;

        const preset = this.availablePresets.find(
            function (candidatePreset: Preset) {
                return candidatePreset.display.trim() === lookupValue.trim();
            }
        );

        if (preset === undefined) {
            throw new TypeError("The preset was not found");
        }

        let hostName = getHostURL(this.props.customerConfig);
        if (preset.local) {
            hostName = "http://127.0.0.1:8080";
        }

        this.setState({
            preset,
            hostName: hostName,
            slotId: preset?.slotId ?? "",
            userId: preset?.userId ?? "",
            parameters: preset?.parameters ?? "",
            seedId: preset?.seedId ?? "",
            lookupItems: preset?.lookupItems ?? false,
            seedItem: undefined,
            requestType: constants.GET_REQUEST
        }, this.update);

    }

    private setRequestType = (e: React.ChangeEvent): void => {
        this.setState({
            requestType: e.currentTarget.id
        });
    }

    private update = (): void => {
        if (this.resultGridElement.current) {
            this.resultGridElement.current.setState({
                isLoaded: false,
                items: []
            }, this.resultGridElement.current.requestItems);
        }
        this.setState({
            seedItem: undefined
        });

        if (this.state.seedId) this.getSeedInfo();
    }

    private getSeedInfo = async (): Promise<void> => {
        if (this.props.customerConfig) {
            await getItemMetadata(this.props.customerConfig, this.state.seedId).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private setSeedItem = async (seedInfo: Record<string, any>): Promise<void> => {
        if (seedInfo && this.props.customerConfig) {
            const seedId = seedIdCheck(this.props.customerConfig.name, seedInfo.id);

            await getItemMetadata(this.props.customerConfig, seedId).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        this.setState({
                            seedItem: response.items[0]
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private toggleSeedAndHistoryPanel = (): void => {
        this.setState({
            showSeedAndHistoryPanel: !this.state.showSeedAndHistoryPanel
        });
    }

    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="test-tool-container">
                    <Row>
                        <Col sm={3}>
                            <SectionHeader>Preset</SectionHeader>
                            <Form className="PresetSettings">
                                <Form.Group>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName><Icons.Collection size="22" /></FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            id="dropdown-presets"
                                            title="Presets"
                                            onChange={(e: any) => this.setFormValues(e)}>
                                            {
                                                this.availablePresets.filter(preset => {
                                                    if (preset.local && !this.props.local) return false;
                                                    return true;
                                                }).map((preset, i) => {
                                                    return (
                                                        <option key={`${i}`} value={`${i}`}>
                                                            {preset.display}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                            <SectionHeader>Environment</SectionHeader>
                            <Form className="EnvironmentSettings">
                                <Form.Group>

                                    <FormEntry>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName><Icons.HddStack size="22" /></FieldName>
                                            </InputGroup.Prepend>
                                            <FieldValue
                                                id="hostInput"
                                                type="string"
                                                placeholder="Host"
                                                onChange={this.setHostName}
                                                value={this.state.hostName} />
                                        </InputGroup>
                                    </FormEntry>
                                    {this.state.preset && this.state.preset.lookupItems &&
                                        <>
                                            <FormEntry>
                                                <Form.Check
                                                    id={constants.GET_REQUEST}
                                                    inline
                                                    checked={this.state.requestType === constants.GET_REQUEST}
                                                    label={constants.GET_REQUEST}
                                                    name="requestType"
                                                    type="radio"
                                                    onChange={this.setRequestType} />
                                                <Form.Check
                                                    id={constants.POST_REQUEST}
                                                    inline
                                                    checked={this.state.requestType === constants.POST_REQUEST}
                                                    label={constants.POST_REQUEST}
                                                    name="requestType"
                                                    type="radio"
                                                    onChange={this.setRequestType} />
                                            </FormEntry>
                                        </>
                                    }
                                </Form.Group>
                            </Form>
                            <SectionHeader>Query Settings</SectionHeader>
                            <Form className="TestToolFormInput" >
                                <Form.Group>
                                    <FormEntry>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName><Icons.FileCode size="22" /></FieldName>
                                            </InputGroup.Prepend>
                                            <FieldValue
                                                id="slotIdInput"
                                                type="string"
                                                placeholder="Slot ID / Path"
                                                onChange={this.setSlotId}
                                                value={this.state.slotId} />
                                        </InputGroup>
                                    </FormEntry>
                                    <FormEntry>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.USER}
                                            includeIcon
                                            initialValue={this.state.userId}
                                            onChangeCallback={this.setUserId}
                                        />
                                    </FormEntry>
                                    <FormEntry>
                                        <RandomField
                                            customer={this.props.customerConfig}
                                            type={constants.SEED}
                                            includeIcon
                                            initialValue={this.state.seedId}
                                            onChangeCallback={this.setSeedId}
                                        />
                                    </FormEntry>
                                    <FormEntry>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <FieldName><Icons.Gear size="22" /></FieldName>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                id="parametersInput"
                                                as="textarea"
                                                rows={3}
                                                placeholder="key:value"
                                                onChange={this.setParameters}
                                                value={this.state.parameters}
                                                style={{ "padding": "0.375rem 0.375rem" }} />
                                        </InputGroup>
                                    </FormEntry>
                                </Form.Group>
                                <FormEntry>
                                    <Row>
                                        <Col>
                                            <Button variant="primary" type="button" onClick={this.update}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </FormEntry>
                            </Form>
                            {this.state.seedItem && !this.state.showSeedAndHistoryPanel &&
                                <>
                                    <SectionHeader>Seed Info</SectionHeader>
                                    <SeedInfo
                                        customerConfig={this.props.customerConfig}
                                        seedItem={this.state.seedItem}
                                    />
                                </>
                            }
                        </Col>
                        <Col sm={9} key="result-grid" id="result-grid" className="justify" style={{ minHeight: "85vh" }}>
                            <SectionHeader>Results</SectionHeader>
                            <ResultGrid
                                ref={this.resultGridElement}
                                hostName={this.state.hostName}
                                slotId={this.state.slotId}
                                userId={this.state.userId}
                                parameters={this.state.parameters}
                                seedId={this.state.seedId}
                                lookupItems={this.state.lookupItems}
                                customer={this.props.customerConfig}
                                requestOnLoad
                                local={this.state.preset.local}
                                showMetadataLink={this.props.metadataAccess}
                                requestType={this.state.requestType}
                                username={this.props.username}
                                groups={this.props.groups}
                                seedCallback={this.setSeedItem}
                                history={this.props.history}
                            />
                        </Col>
                    </Row>
                    <SeedAndUserHistoryPanel
                        customerConfig={this.props.customerConfig}
                        userId={this.state.userId}
                        seedItem={this.state.seedItem}
                        showPanel={this.state.showSeedAndHistoryPanel}
                        showHidePanelCallback={this.toggleSeedAndHistoryPanel}
                    />
                </Container>
            </div>
        );
    }
}

export default withRouter(TestTool);