import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

type SaveModelConfigModalProperties = {
    modelConfigModelType?: string,
    modelConfigVersion?: string,
    showModal: boolean,
    closeCallback: (show: boolean) => void,
    saveCallback: () => void
}

export default class SaveModelConfigModal extends React.Component<SaveModelConfigModalProperties> {

    public render(): ReactNode {
        return (
            <Modal show={this.props.showModal} onHide={() => { this.props.closeCallback(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>Save model config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Save model config <b>{this.props.modelConfigModelType}</b><br/>
                with version: <b>{this.props.modelConfigVersion}</b><br/>
                <br/>
                <b>WARNING:</b> Saving will overwrite any existing model config<br/>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { this.props.closeCallback(false); }}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => { this.props.saveCallback(); this.props.closeCallback(false);}}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
        );
    }
}
