import React, { ReactNode } from "react";
import { Alert, Button, Col, Form, InputGroup, Row, Modal, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Trash as ClearIcon, Search as SearchIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import MetadataCarousel from "../MetadataCarousel";
import { SearchResults } from "../SearchResults";
import * as utils from "../../utils/Utils";
import { getSearchURL, makeSlotRequest, postBlockingEditorial } from "../../utils/Requests";
import Logger from "../../utils/Logger";
import moment from "moment";
import { seedIdCheck } from "../../utils/Utils";

import {DATE_FORMAT, STARTDATE_FORMAT, ENDDATE_FORMAT} from "../../constants";

const ModalHeader = styled(Modal.Header)`
    padding-bottom: 0px;
`;

const DetailsRow = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SearchRow = styled(Row)`
    border-top: gray thin solid;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const FormRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    min-width: 75px;
`;

const CentralCol = styled(Col)`
    text-align: center;
`;

const BlockItemAlert = styled(Alert)`
    position: absolute;
    top: 206px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

type BlockingModalProperties = {
    customer: CustomerConfig,
    showModal: boolean,
    closeCallback: () => void,
    addCallback: () => void,
    username: string,
    groups: string[],
    slots: Slot[]
}

type BlockingModalState = {
    description: string,
    startDate: string,
    endDate: string,
    slotId: string,
    blockingItems: Array<Record<string, any>>,
    searchItems: Array<Record<string, any>>,
    searchTerm: string,
    searchLoading: boolean,
    searchLoaded: boolean,
    searchError?: Error | null,
    validated: boolean,
    showBlockingError: boolean,
    promoError?: Error | null
}

export default class BlockingModal extends React.Component<BlockingModalProperties, BlockingModalState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: BlockingModalProperties) {
        super(props);

        this.timeoutId = null;

        const startDate = moment().utc().format(DATE_FORMAT);
        const endDate = moment().utc().endOf("year").format(DATE_FORMAT);

        this.state = {
            description: "",
            startDate: startDate,
            endDate: endDate,
            blockingItems: [],
            slotId: "all",
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            validated: false,
            showBlockingError: false
        };
    }

    private update = (): void => {
        this.setState({
            searchLoading: true,
            searchLoaded: false
        }, this.requestItems);
    }

    private handleSearchError(searchError: Error) {
        Logger.error(searchError);
        this.setState({
            searchLoading: false,
            searchLoaded: true,
            searchError
        });
    }

    private getUrl = (includeParams = true) => {
        const customer = this.props.customer;
        let url = getSearchURL(customer);

        if (includeParams) {
            const formattedParameters = utils.getFormattedParameters(`q: ${this.state.searchTerm}`);
            url += "?_debug_metadata=true&ignore=testtool" + formattedParameters;

            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);
        }

        return url;
    }

    public async requestItems(): Promise<void>  {
        await makeSlotRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleSearchError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleSearchError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                searchLoading: false,
                                searchLoaded: true,
                                searchItems: items || [],
                                searchError: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleSearchError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private searchReset = (): void => {
        this.setState({
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private renderSearchResults(): ReactNode {
        const {searchError, searchLoading, searchLoaded} = this.state;

        if (searchError) {
            return <Row className="justify-content-center">
                Error - {searchError.message}
            </Row>;
        }

        if (searchLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!searchLoading && !searchLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        }
    }

    private selectItem = (item: Record<string,any>): void => {
        let { blockingItems: BlockingItems } = this.state;
        const itemExists = BlockingItems.find((promoItem) => { return item.id === promoItem.id; });

        if (itemExists) {
            BlockingItems = BlockingItems.filter((promoItem) => {
                return item.id !== promoItem.id;
            });
        } else {
            BlockingItems.push(item);
        }

        this.setState({
            blockingItems: BlockingItems
        });
    }

    private setDescription = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            description: event.currentTarget.value
        });
    }

    private setStartDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            startDate: event.currentTarget.value
        });
    }

    private setEndDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            endDate: event.currentTarget.value
        });
    }

    private setSlotId = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            slotId: event.currentTarget.value
        });
    }

    private saveBlocking = async () => {
        const blockings = this.state.blockingItems.map((item, index) => {
            return {
                "slotId": this.state.slotId,
                "thingId": seedIdCheck(this.props.customer.name, item.id),
                "boostPercentage": 0,
                "block": true,
                "startDate": moment(this.state.startDate).utc().format(STARTDATE_FORMAT),
                "endDate": moment(this.state.endDate).utc().format(ENDDATE_FORMAT),
                "description": this.state.description
            };
        });

        await postBlockingEditorial(
            this.props.customer,
            blockings
        ).then((response) => {
            if (response.ok) {

                if (response.status === 200) {
                    this.resetStateAndClose();
                } else {
                    this.handleSaveError(Error(`Error during save, API response: ${response.status} - ${response.statusText}`));
                }
            }
        }).catch((error: Error) => {
            this.handleSaveError(error);
        });
    }

    private handleSaveError(error: Error) {
        Logger.error(error);
        this.setState({
            showBlockingError: true
        });
    }

    private closeError = (): void => {
        this.setState({
            showBlockingError: false
        });
    }

    private resetStateAndClose() {
        const startDate = moment().utc().format(DATE_FORMAT);
        const endDate = moment().utc().endOf("year").format(DATE_FORMAT);

        this.setState({
            description: "",
            startDate,
            endDate,
            blockingItems: [],
            slotId: "all",
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null,
            validated: false,
            showBlockingError: false,
            promoError: null
        }, () => {
            this.props.addCallback();
            this.props.closeCallback();
        });
    }

    private closeModal = (): void => {
        this.resetStateAndClose();
    }

    public render(): ReactNode {

        const availableSlots = this.props.slots.sort((a,b) => {
            return a.slotName.localeCompare(b.slotName);
        }).map((slot) => {
            return (
                <option value={slot.slotId} key={slot.slotId}>
                    {slot.slotName} ({slot.slotId})
                </option>
            );
        });

        availableSlots.push(
            <option value={"all"} key={"all"}>
                All
            </option>
        );

        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-60w"
                onHide={this.closeModal}
            >
                <ModalHeader closeButton>
                    <Modal.Title>Add New Item Blocks</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <BlockItemAlert
                        variant='danger'
                        show={this.state.showBlockingError}
                        onClose={this.closeError}
                        dismissible
                    >
                        Error saving item blocks
                    </BlockItemAlert>
                    <DetailsRow>
                        <Col className="mx-auto" md={4} style={{ marginLeft: "10px", marginRight: "10px"}}>
                            <Form noValidate validated={this.state.validated} onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    this.setState({
                                        validated: true
                                    });

                                    const formValid = event.currentTarget.checkValidity();
                                    if (formValid) {
                                        this.saveBlocking();
                                    }
                                }}>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Slot</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            id="dropdown-slot"
                                            title="Slot"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.slotId}
                                            onChange={this.setSlotId}
                                        >
                                            {availableSlots}
                                        </Form.Control>
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup hasValidation>
                                        <InputGroup.Prepend>
                                            <FieldName>Description</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            id="blocking-name"
                                            required
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.description}
                                            placeholder="Description"
                                            onChange={this.setDescription}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a description for these blocked items.
                                    </Form.Control.Feedback>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>From</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="date"
                                            id={"Blocking-from"}
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.startDate}
                                            onChange={this.setStartDate}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>To</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="date"
                                            id="Blocking-to"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.endDate}
                                            onChange={this.setEndDate}
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>

                            <FormRow>
                                <Col className="d-flex justify-content-end">
                                    <Button type="submit">Create</Button>
                                </Col>
                            </FormRow>
                            </Form>
                        </Col>
                        <Col md={8} style={{borderLeft: "thin gray solid"}}>
                            <MetadataCarousel
                                items={this.state.blockingItems}
                                customer={this.props.customer}
                                username={this.props.username}
                                groups={this.props.groups}
                                lessItems
                                hideInfoIcon
                                removeItemCallback={this.selectItem}
                            />
                        </Col>
                    </DetailsRow>
                    <SearchRow>
                        <CentralCol md>
                            <FormRow style={{paddingBottom: "10px"}}>
                            <Col md={10} className="mx-auto">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Search</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="search-string"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.searchTerm}
                                            onChange={this.setSearchTerm}
                                            onKeyPress={this.checkKey}
                                            onKeyUp={this.checkBackspaceKey}
                                            placeholder="Enter Search Terms here, e.g. Title, Actor"
                                            autoComplete="off"
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                                <Button variant="primary" type="button" onClick={this.update}>
                                                    <SearchIcon />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                                <Button variant="dark" type="button" onClick={this.searchReset}>
                                                    <ClearIcon />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <div style={{overflowY: "scroll", height: "45vh"}}>
                            {this.renderSearchResults()}
                            {this.state.searchLoaded &&

                                    <SearchResults
                                        customer={this.props.customer}
                                        showMetadataLink={false}
                                        showOverlay={false}
                                        items={this.state.searchItems}
                                        username={this.props.username}
                                        groups={this.props.groups}
                                        selectCallback={this.selectItem}
                                        promotedItems={this.state.blockingItems}
                                        smallImages
                                    />
                                }
                            </div>
                        </CentralCol>
                    </SearchRow>
                </Modal.Body>
            </Modal>
        );
    }
}
