import React, { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

class NotFound extends React.Component {
    public render(): ReactNode {
        return (
            <div>
                <Container className="mw-100" key="not-found">
                    <Row>
                        <Col md>
                            <br/>
                            <h3>Page not found</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default NotFound;