import React, { ReactNode } from "react";
import { Popover } from "react-bootstrap";

import * as utils from "../utils/Utils";

type MetadataPopoverProperties = {
    item: Record<string, any>,
    minimal?: boolean
}


export default class MetadataPopover extends React.Component<MetadataPopoverProperties> {

    public render(): ReactNode {
        const {item, minimal, ...props} = this.props;

        if (item.id && item.name) {
            const flavourText = utils.getFlavourText(item);

            const releaseYear = utils.getDatePublished(item);
            const genres = utils.getGenres(item);
            const director = utils.getDirector(item["director"]).join(", ");
            const actors = utils.getActors(item["actor"]).join(", ");
            const contentRating = utils.getContentRating(item);

            if (this.props.minimal) {
                return (
                    <Popover id="popover-basic" {...props}>
                        <Popover.Title as="h4">{item["name"]}</Popover.Title>
                        <Popover.Content>
                            {releaseYear && <><b>Release Year</b>: {releaseYear}<br/></>}
                            {genres.length > 0 && <><b>Genre</b>: {genres.join(",")}<br/></>}
                            {actors && actors.length > 0 && <><b>Actors</b>: {actors}</>}
                        </Popover.Content>
                    </Popover>
                );
            } else {
                return (
                    <Popover id="popover-basic" {...props}>
                        <Popover.Title as="h4">{item["name"]}</Popover.Title>
                        <Popover.Content>
                            {contentRating && <><b>Content Rating</b>: {contentRating}<br/></>}
                            {releaseYear && <><b>Release Year</b>: {releaseYear}<br/></>}
                            {genres.length > 0 && <><b>Genre</b>: {genres.join(",")}<br/></>}
                            {director && <><b>Directed By</b>: {director}<br/></>}
                            {actors && actors.length > 0 && <><b>Actors</b>: {actors}</>}
                            {flavourText && <div>{flavourText}</div>}
                            <hr/>
                            <b>ID</b>: {item["id"]}<br/>
                        </Popover.Content>
                    </Popover>
                );
            }
        } else {
            return (
                <Popover id="popover-basic" {...props}>
                    <Popover.Title as="h4">Unknown</Popover.Title>
                </Popover>
            );
        }
    }
}