/* eslint-disable camelcase */
import React, { ReactNode } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { getAvailableCustomers } from "../config/Customers";
import parsedData from "../../src/assets/metrics-hyperparameters.json";

const StatsTable = styled(Table)`
    background-color: #343a40;
`;

const HPtd = styled.td`
    backdrop-filter: brightness(1.3);
`;

type ModelStatsState = {
    customer: CustomerConfig,
    parsedData: CsvData[],
}

type ModelStatsProperties = {
    standalone?: boolean,
    groups: string[],
    username: string
}

interface CsvData {
    name: string;
    precision_5: string;
    precision_10: string;
    recall_at_5: string;
    recall_at_10: string;
    rec_play_intersection_5: number;
    rec_play_intersection_10: number;
    chart_recs_proportion_5: number;
    chart_recs_proportion_10: number;
    hyperparameters_max_items: number;
    hyperparameters_chart_delta_days: string;
    hyperparameters_n_clusters: number;
    hyperparameters_coding_width: number;
    hyperparameters_layer_widths: string;
    hyperparameters_dropout: number;
    hyperparameters_learning_rate: number;
    hyperparameters_refeed: boolean;
    hyperparameters_cluster_charts_delta_days: number;
    hyperparameters_depth: number;
    hyperparameters_pca_explain_variance: number;
    hyperparameters_cluster_training_min_plays: number;
    hyperparameters_early_stopping_delta: number;
    hyperparameters_max_epochs: number;
    hyperparameters_noise: number;
    [key: string]: unknown;
}


export default class ModelStats extends React.Component<ModelStatsProperties, ModelStatsState> {

    constructor(props: ModelStatsProperties) {
        super(props);

        this.state = {
            customer: getAvailableCustomers(this.props.groups)[0],
            parsedData: []
        };
    }

    public componentDidMount(): void {
        this.setState({
            parsedData: parsedData
        });
    }

    private renderTableHeader() {
        const header = this.state.parsedData.length > 0 ? Object.keys(this.state.parsedData[0]) : [];

        const skipHeaders = [
            "hyperparameters_chart_delta_days",
            "hyperparameters_layer_widths",
            "hyperparameters_refeed"
        ];

        return header.map((key, index) => {
            if (index === 0) return <th key={index} style={{textAlign: "center"}}>MODEL NAME</th>;
            const hyperparameterHeader = key.indexOf("hyperparameters") >= 0;
            if (!skipHeaders.includes(key)) {
                return (
                    <th key={index} style={{textAlign: "center", verticalAlign: "middle", backdropFilter: hyperparameterHeader ? "brightness(1.2)" : "brightness(1.0)"}}>
                        {key.toUpperCase()
                            .replaceAll("PRECISION", "")
                            .replaceAll("RECALL_AT", "")
                            .replaceAll("REC_PLAY_INTERSECTION", "")
                            .replaceAll("CHART_RECS_PROPORTION", "")
                            .replaceAll("HYPERPARAMETERS", "")
                            .replaceAll("_", " ")}
                    </th>
                );
            } else {
                return <></>;
            }
        });
    }

    private renderTableData() {
        return this.state.parsedData.map((
            data: {
                name: string;
                precision_5: string;
                precision_10: string;
                recall_at_5: string;
                recall_at_10: string;
                rec_play_intersection_5: number;
                rec_play_intersection_10: number;
                chart_recs_proportion_5: number;
                chart_recs_proportion_10: number;
                hyperparameters_max_items: number;
                hyperparameters_chart_delta_days: string;
                hyperparameters_n_clusters: number;
                hyperparameters_coding_width: number;
                hyperparameters_layer_widths: string;
                hyperparameters_dropout: number;
                hyperparameters_learning_rate: number;
                hyperparameters_refeed: boolean;
                hyperparameters_cluster_charts_delta_days: number;
                hyperparameters_depth: number;
                hyperparameters_pca_explain_variance: number;
                hyperparameters_cluster_training_min_plays: number;
                hyperparameters_early_stopping_delta: number;
                hyperparameters_max_epochs: number;
                hyperparameters_noise: number;
            }, index: React.Key | null | undefined) => {
            const {
                name,
                precision_5,
                precision_10,
                recall_at_5,
                recall_at_10,
                rec_play_intersection_5,
                rec_play_intersection_10,
                chart_recs_proportion_5,
                chart_recs_proportion_10,
                hyperparameters_max_items,
                hyperparameters_n_clusters,
                hyperparameters_coding_width,
                hyperparameters_dropout,
                hyperparameters_learning_rate,
                hyperparameters_cluster_charts_delta_days,
                hyperparameters_depth,
                hyperparameters_pca_explain_variance,
                hyperparameters_cluster_training_min_plays,
                hyperparameters_early_stopping_delta,
                hyperparameters_max_epochs,
                hyperparameters_noise
            } = data;
            return (
                <tr key={index} style={{textAlign: "center"}}>
                    <td>{name}</td>
                    <td>{precision_5.substring(0,5)}</td>
                    <td>{precision_10.substring(0,5)}</td>
                    <td>{recall_at_5.substring(0,5)}</td>
                    <td>{recall_at_10.substring(0,5)}</td>
                    <td>{rec_play_intersection_5}</td>
                    <td>{rec_play_intersection_10}</td>
                    <td>{chart_recs_proportion_5}</td>
                    <td>{chart_recs_proportion_10}</td>
                    <HPtd>{hyperparameters_max_items}</HPtd>
                    <HPtd>{hyperparameters_n_clusters}</HPtd>
                    <HPtd>{hyperparameters_coding_width}</HPtd>
                    <HPtd>{hyperparameters_dropout}</HPtd>
                    <HPtd>{hyperparameters_learning_rate}</HPtd>
                    <HPtd>{hyperparameters_cluster_charts_delta_days}</HPtd>
                    <HPtd>{hyperparameters_depth}</HPtd>
                    <HPtd>{hyperparameters_pca_explain_variance}</HPtd>
                    <HPtd>{hyperparameters_cluster_training_min_plays}</HPtd>
                    <HPtd>{hyperparameters_early_stopping_delta}</HPtd>
                    <HPtd>{hyperparameters_max_epochs}</HPtd>
                    <HPtd>{hyperparameters_noise}</HPtd>
                </tr>
            );
        });
    }

    public render(): ReactNode {
        return (
            <Container className="mw-100">
                <Row>
                    <Col>
                    <h5>RFY Model Statistics</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.parsedData && (
                            <StatsTable striped bordered hover variant="dark" size="sm">
                            <thead>
                                    <tr>
                                        <th></th>
                                        <th colSpan={2} style={{textAlign: "center", verticalAlign: "middle"}}>PRECISION</th>
                                        <th colSpan={2} style={{textAlign: "center", verticalAlign: "middle"}}>RECALL AT</th>
                                        <th colSpan={2} style={{textAlign: "center", verticalAlign: "middle"}}>RECS PLAY INTERSECTION</th>
                                        <th colSpan={2} style={{textAlign: "center", verticalAlign: "middle"}}>CHART RECS PROPORTION</th>
                                        <th colSpan={12} style={{textAlign: "center", verticalAlign: "middle", backdropFilter: "brightness(1.2)" }}>HYPERPARAMETERS</th>
                                    </tr>
                                    <tr>{this.renderTableHeader()}</tr>
                                </thead>
                                <tbody>{this.renderTableData()}</tbody>
                            </StatsTable>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}