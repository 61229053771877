import React, { ReactNode } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { CaretRightFill as GoIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import Branding from "../../config/Branding";
import HistoryCarousel from "./HistoryCarousel";
import Logger from "../../utils/Logger";
import { getUserHistory, makeEditorialLaneSlotRequest } from "../../utils/Requests";
import { getCustomerConfig } from "../../config/Customers";
import MetadataCarousel from "../MetadataCarousel";
import RandomField from "../fields/RandomField";
import * as constants from "../../constants";

const CentralCol = styled(Col)`
    text-align: center;
`;

const ModelSelectorRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${(props) => (props.standalone ? "30px" : "63px")};
`;

const HeroElementCol = styled(Col)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const EditoialContainerRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 20px;
`;

const UserHistoryRow = styled(EditoialContainerRow)`
`;

type TestTabProperties = {
    customer: string,
    portalUsername: string,
    portalGroups: string[],
    selectedEditorialID: string,
}

type TestTabState = {
    userId: string,
    items: Array<any>,
    userHistory: Array<any>,
    hgLoading: boolean,
    hgLoaded: boolean,
    uhLoading: boolean,
    uhLoaded: boolean
}


export default class TestTab extends React.Component<TestTabProperties, TestTabState> {

    constructor(props: TestTabProperties) {
        super(props);

        this.state = {
            userId: "",
            items: [],
            userHistory: [],
            hgLoading: false,
            hgLoaded: false,
            uhLoading: false,
            uhLoaded: false
        };
    }

    private setUserId = (event: string, submit: boolean): void => {
        this.setState({
            userId: event
        }, () => {
            if (submit) {
                this.update();
            }
        });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            this.update();
        }
    }

    private update = (): void => {
        this.requestItems();
        this.requestUserHistory();
    };

    private async requestItems(): Promise<void>  {
        this.setState({
            hgLoading: true,
            hgLoaded: false,
            items: []
        });

        const slotRequest = makeEditorialLaneSlotRequest(
            getCustomerConfig(this.props.customer),
            this.state.userId,
            this.props.portalUsername,
            this.props.portalGroups,
            this.props.selectedEditorialID
        );

        await slotRequest.then((response) => {
            if (response.status !== 200) {
                response.json().then((error) => {
                    Logger.error(error);
                });
            } else {
                response.json().then((result) => {
                    this.setState({
                        items: result.items,
                        hgLoaded: false
                    });
                });
            }
        }).catch((error) => {
            this.setState({
                items: [],
                hgLoading: false,
                hgLoaded: true
            }, () => Logger.log(error));
        });
    }

    private async requestUserHistory(): Promise<void>  {
        this.setState({
            uhLoading: true,
            uhLoaded: false,
            userHistory: []
        });

        await getUserHistory(getCustomerConfig(this.props.customer), this.state.userId, true).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                this.setState({
                    userHistory: [],
                    uhLoading: false,
                    uhLoaded: true
                });
            }
        }).then(jsonOutput => {
            const historyItems = jsonOutput.history;

            this.setState({
                userHistory: historyItems,
                uhLoading: false,
                uhLoaded: true
            });
        }).catch(error => {
            this.setState({
                userHistory: [],
                uhLoading: false,
                uhLoaded: true
            }, () => Logger.log(error));
        });
    }

    public render(): ReactNode {
        return (
            <>
            <ModelSelectorRow className="justify-content-center sticky-banner">
                <CentralCol md>
                    <Form className="HeroSettings" onSubmit={(event) => {event.preventDefault();}}>
                            <Form.Row className="justify-content-center">
                                <HeroElementCol md={3}>
                                    <RandomField
                                        customer={getCustomerConfig(this.props.customer)}
                                        includeIcon
                                        type={constants.USER}
                                        initialValue={this.state.userId}
                                        onChangeCallback={this.setUserId}
                                        onKeyUpCallback={this.checkKey}
                                    />
                                </HeroElementCol>
                                <HeroElementCol md={1}>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="submit-tooltip">Submit</Tooltip>}>
                                        <Button variant="primary" type="button" onClick={this.update}>
                                            <GoIcon />
                                        </Button>
                                    </OverlayTrigger>
                                </HeroElementCol>
                            </Form.Row>
                        </Form>
                    </CentralCol>
                </ModelSelectorRow>

                <EditoialContainerRow className="justify-content-center">
                    {this.state.items.length > 0 &&
                        <Col md={12}>
                            <MetadataCarousel
                                items={this.state.items}
                                customer={getCustomerConfig(this.props.customer)}
                                username={this.props.portalUsername}
                                groups={this.props.portalGroups}
                                hideInfoIcon
                                modal
                            />
                        </Col>
                    }
                </EditoialContainerRow>

                <UserHistoryRow className="justify-content-center">
                    <CentralCol>
                        <Row className="justify-content-center">
                            {(this.state.uhLoading || this.state.uhLoaded) && <h5>User History (Brands)</h5>}
                        </Row>
                        <Row className="justify-content-center">
                            <Col>
                                <HistoryCarousel items={this.state.userHistory} loading={this.state.uhLoading} loaded={this.state.uhLoaded}/>
                            </Col>
                        </Row>
                    </CentralCol>
                </UserHistoryRow>
            </>
        );
    }

}
