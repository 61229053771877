import React, { ReactNode } from "react";
import { Button, Image, Table } from "react-bootstrap";
import { ArrowRightCircleFill as ArrowIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import { getPlaceholderImage } from "../../utils/Utils";

const SearchImage = styled(Image)`
    height: 60px;
    width: unset;
`;

const Cell = styled.td`
    vertical-align: middle !important;
`;

type SearchTableProperties = {
    items: Array<SearchItem>,
    noResults: boolean,
    callback?: (item: SearchItem) => void
}

export default class SearchTable extends React.Component<SearchTableProperties> {

    private makeCallback = (item: SearchItem) => {
        if (this.props.callback) {
            this.props.callback(item);
        }
    }

    private convertItemsToRows(): ReactNode {
        if (this.props.items.length > 0) {
            return this.props.items.map((item)=> {
                return (
                    <tr key={item.id}>
                        <Cell><SearchImage src={item.image} rounded onError={(event) => getPlaceholderImage(event, item)}/></Cell>
                        <Cell>{item.name}</Cell>
                        <Cell>
                            <Button variant="secondary" onClick={() => {this.makeCallback(item);}}><ArrowIcon /></Button>
                        </Cell>
                    </tr>
                );
            });
        } else if (this.props.noResults) {
            return (
                <tr>
                    <Cell>No matching results found</Cell>
                </tr>
            );
        }
    }

    public render(): ReactNode {
        return (
            <Table striped bordered hover>
                <tbody>
                    {this.convertItemsToRows()}
                </tbody>
            </Table>
        );
    }

}