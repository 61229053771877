import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import AceEditor from "react-ace";
import styled from "styled-components";

const StyledAceEditor = styled.div`
    .ace_scrollbar-v, .ace_scrollbar-h {
        display: none;
    }
`;

type InvalidSlotModalProperties = {
    slotId: string,
    slotName: string,
    slotType?: string,
    showModal: boolean,
    jsonData?: string,
    closeCallback: (show: boolean) => void,
    onSave: (newJsonData: string) => void;
};

type InvalidSlotModalState = {
    jsonData: string;
};

export default class InvalidSlotModal extends React.Component<InvalidSlotModalProperties, InvalidSlotModalState> {

    constructor(props: InvalidSlotModalProperties) {
        super(props);

        this.state = {
            jsonData: this.props.jsonData || ""
        };
    }

    public componentDidUpdate(prevProps: InvalidSlotModalProperties): void {
        if (prevProps.jsonData !== this.props.jsonData) {
            this.setState({ jsonData: this.props.jsonData || "" });
        }
    }

    private validateJson = (): boolean => {
        if (!this.state.jsonData) {
            return false;
        }

        try {
            JSON.parse(this.state.jsonData);
            return true;
        } catch (e) {
            return false;
        }
    };

    private handleJsonDataChange = (newJsonData: string): void => {
        this.setState({ jsonData: newJsonData });
    };

    private handleSave = (): void => {
        this.props.onSave(this.state.jsonData);
        this.props.closeCallback(false);
    };

    public render(): ReactNode {
        const isValidJson = this.validateJson();
        return (
            <Modal show={this.props.showModal} onHide={() => { this.props.closeCallback(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "20px" }}>
                        <span style={{ color: "red", marginRight: "8px", fontSize: "24px" }}>ERROR</span>
                        invalid JSON data, please repair this:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StyledAceEditor>
                        <AceEditor
                            style={{ width: "100%", height: "250px" }}
                            mode="json"
                            theme="monokai"
                            name="slotEditor"
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                                useSoftTabs: true,
                                tabSize: 2
                            }}
                            value={this.state.jsonData}
                            onChange={this.handleJsonDataChange}
                            wrapEnabled
                        />
                    </StyledAceEditor>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.props.closeCallback(false); }}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.handleSave}
                        disabled={!isValidJson}
                    >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}