import React, { ReactNode } from "react";
import {
    Button, Col, Container, InputGroup,
    OverlayTrigger, Row, Tooltip, Form, Alert
} from "react-bootstrap";
import styled from "styled-components";
import "rc-slider/assets/index.css";

import { InitialModelParameters } from "../config/ModelParameters";
import { ParameterSlider } from "../components/ParameterSlider";

const AddPromotionsCol = styled(Col)`
    background: black;
    margin-top: 10px;
    border-radius: 3px;
    padding: 5px;
`;

const FieldIcon = styled(InputGroup.Text)`
    background: black;
    padding-left: 10px;
    padding-right: 10px;
`;

const ParametersAlert = styled(Alert)`
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: 10;
    min-width: 200px;
    height: 42px;
`;

type ModelParametersEditorState = {
    modelType: string,
    modelVersion: string,
    showSuccess: boolean
}

type ModelParametersEditorProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

class ModelParametersEditor extends React.Component<ModelParametersEditorProperties, ModelParametersEditorState> {

    constructor(props: ModelParametersEditorProperties) {
        super(props);

        this.state = {
            modelType: "More Like This",
            modelVersion: "mlt_20220926",
            showSuccess: false
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.setState({
            modelType: "More Like This"
        });
    }

    public selectModelType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            modelType: event.target.value,
            modelVersion: InitialModelParameters[event.target.value][0].name
        });
    }

    public selectModelVersion = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            modelVersion: event.target.value
        });
    }

    private renderModelParameters(): ReactNode {

        const model = InitialModelParameters[this.state.modelType];

        const parameters = model.filter(mv => mv.name === this.state.modelVersion)[0].weights;

        if (parameters && parameters.length > 0) {
            return (
                <>
                    {parameters.map((item, i) => {
                        return <ParameterSlider key={i} name={item["name"]} initialValue={item["value"]} />;
                    })}
                </>
            );
        } else {
            return <></>;
        }
    }

    private getModelVersions(): Array<string> {
        const modelVersions = InitialModelParameters[this.state.modelType];

        const modelVersionNames: Array<string> = [];
        modelVersions.forEach(version => { modelVersionNames.push(version.name); });

        return modelVersionNames;
    }

    private saveParameters = (): void => {
        setTimeout(() => {
            this.setState({
                showSuccess: true
            });
        }, 500);
        setTimeout(() => {
            this.setState({
                showSuccess: false
            });
        }, 2500);
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    public render(): ReactNode {
        const modelKeys = Object.keys(InitialModelParameters);
        const modelVersions = this.getModelVersions();

        return (
            <>
                <ParametersAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Model parameters saved
                </ParametersAlert>
                <Container className="mw-100" key="model-parameters">
                    <Row>
                        <AddPromotionsCol md={3} className="d-flex" style={{ marginLeft: "150px" }}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <OverlayTrigger
                                        overlay={<Tooltip id="model-type-tooltip">Model Type</Tooltip>}
                                    >
                                        <FieldIcon>Model Type</FieldIcon>
                                    </OverlayTrigger>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id={"modelSelector"}
                                    title="Select Model Type"
                                    value={this.state.modelType}
                                    style={{ padding: "0.375rem" }}
                                    onChange={this.selectModelType}
                                >
                                    {modelKeys.map((modelName: string, i: number) => {
                                        return (
                                            <option value={modelName} key={i}>
                                                {modelName}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </AddPromotionsCol>
                        <AddPromotionsCol md={3} className="d-flex justify-content-start">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <OverlayTrigger
                                        overlay={<Tooltip id="model-version-tooltip">Model Version</Tooltip>}
                                    >
                                        <FieldIcon>Model Version</FieldIcon>
                                    </OverlayTrigger>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id={"modelSelector"}
                                    title="Select Model Type"
                                    value={this.state.modelVersion}
                                    style={{ padding: "0.375rem" }}
                                    onChange={this.selectModelVersion}
                                >
                                    {modelVersions.map((modelVersion: string, i: number) => {
                                        return (
                                            <option value={modelVersion} key={i}>
                                                {modelVersion}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </AddPromotionsCol>
                        <AddPromotionsCol md={3} className="d-flex justify-content-end align-items-center">
                            <Button onClick={this.saveParameters}>Save</Button>
                        </AddPromotionsCol>
                    </Row>

                    <Row className="justify-content-center" style={{ marginTop: "20px" }}>
                        <Col md={6}>
                            <Row><h5>Adjust Similiarity Weights</h5></Row>
                            {this.renderModelParameters()}
                        </Col>
                        <Col md={4} />
                    </Row>
                </Container>
            </>
        );
    }
}

export default ModelParametersEditor;